import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";

import LoadingComponent from "~/components/LoadingComponent/LoadingComponent";
import PriceListContainer from "./containers/ManagerProductContainer/PriceListContainer";
import ManagerTreasureQuestionsContainer from "./containers/ManagerTreasureQuestionsContainer";

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import("~/pages/HomePage/HomePage"));
const NotFound = lazy(() => import("~/pages/NotFound/NotFound"));
const LoginCenterPage = lazy(() =>
  import("~/pages/LoginCenterPage/LoginCenterPage")
);

const UpdateCSVContainer = lazy(() =>
  import("~/containers/UpdateCSVContainer")
);

//Componenentes de acordo ao item de menu
const DashboardsContainer = lazy(() =>
  import("~/containers/DashboardsContainer")
);
const ProductContainer = lazy(() => import("~/containers/ProductContainer"));
const ProductCreateContainer = lazy(() =>
  import("~/containers/ProductCreateContainer")
);
const ProductUpdateContainer = lazy(() =>
  import("~/containers/ProductUpdateContainer")
);
const CustomerContainer = lazy(() => import("~/containers/CustomerContainer"));
const AdministratorContainer = lazy(() =>
  import("~/containers/AccountContainer")
);
const QuestionManager = lazy(() =>
  import("~/containers/QuestionManagerContainer/QuestionManagerContainer")
);
const ApplicationContainer = lazy(() =>
  import("~/containers/ApplicationContainer")
);
const CategoryContainer = lazy(() => import("~/containers/CategoryContainer"));

const HomeStoreContainer = lazy(() =>
  import("~/containers/HomeStoreContainer")
);
const ManagerProductsContainer = lazy(() =>
  import("~/containers/ManagerProductContainer")
);
const ManagerCategoryContainer = lazy(() =>
  import("~/containers/ManagerCategoryContainer")
);
const ManagerApplicationContainer = lazy(() =>
  import("~/containers/ManagerApplicationContainer")
);
const ManagerAccountContainer = lazy(() =>
  import("~/containers/ManagerAccountContainer")
);
const ManagerClientContainer = lazy(() =>
  import("~/containers/ManagerCustomerContainer")
);
const ManagerPropertiesContainer = lazy(() =>
  import("~/containers/ManagerPropertiesContainer")
);
const ComparativeContainer = lazy(() =>
  import("~/containers/ComparativeContainer")
);

const ManagerCustomersGroupContainer = lazy(() =>
  import("~/containers/ManagerCustomersGroupContainer")
);

const CustomersGroupContainer = lazy(() =>
  import("~/containers/CustomersGroupContainer")
);

const TreasureContainer = lazy(() => import("~/containers/TreasureContainer"));

const ManagerTreasureContainer = lazy(() =>
  import("~/containers/ManagerTreasureContainer")
);

const ManagerTotalByCustomerContainer = lazy(() =>
  import("~/containers/ManagerTotalByCustomerContainer")
);

const ManagerComparativeContainer = lazy(() =>
  import("~/containers/ManagerComparativeContainer")
);

const ConnectComparativeContainer = lazy(() =>
  import("~/containers/ConnectComparativeContainer")
);

const UpdateComparativeContainer = lazy(() =>
  import("~/containers/ComparativeUpdateContainer")
);

const BrandCreateContainer = lazy(() =>
  import("~/containers/BrandCreateContainer")
);

const BrandUpdateContainer = lazy(() =>
  import("~/containers/BrandUpdateContainer")
);

const ManagerCustomersByServiceContainer = lazy(() =>
  import("~/containers/ManagerCustomersByServiceContainer")
);

const ManagerMostSearchedRegionsContainer = lazy(() =>
  import("~/containers/ManagerMostSearchedRegionsContainer")
);

const ManagerRatingRankingContainer = lazy(() =>
  import("~/containers/ManagerRatingRankingContainer")
);

const ManagerBrandContainer = lazy(() =>
  import("~/containers/ManagerBrandContainer")
);

const ManagerSalesContainer = lazy(() =>
  import("~/containers/ManagerSalesContainer")
);

const ManagerEvaluationsContainer = lazy(() =>
  import("~/containers/ManagerEvaluationsContainer")
);

const ManagerTreasureEvaluationsContainer = lazy(() =>
  import("~/containers/ManagerTreasureEvaluationsContainer")
);

const ManagerMessageContainer = lazy(() =>
  import("~/containers/ManagerMessageContainer")
);

//Rotas globais de nossa aplicação manager.
export default inject(({ authStore }) => ({ authStore }))(
  withRouter(
    observer((props) => {
      //função logout
      const logout = () => {
        props.authStore.logout().then(() => props.history.push("/"));
      };

      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/error-page" component={NotFound} />
            <Route exact path="/login" render={() => <Redirect to={"/"} />} />
            <Route exact path="/logout" render={() => logout()} />
            <PrivateRoute
              path="/"
              component={HomePage}
              isAuthenticated={props.authStore.isAuthenticated}
            />
            <Redirect to="/error-page" />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = () => (
  <Switch>
    <Route exact path="/update-csv/:type" component={UpdateCSVContainer} />
    <Route exact path="/cadastrar-usuarios" component={CustomerContainer} />
    <Route exact path="/cadastrar-marcas" component={BrandCreateContainer} />
    <Route exact path="/cadastrar-categoria" component={CategoryContainer} />
    <Route exact path="/cadastrar-aplicacao" component={ApplicationContainer} />
    <Route exact path="/cadastrar-tesouros" component={TreasureContainer} />

    {/* Reports */}
    <Route
      exact
      path="/total-usuarios"
      component={ManagerTotalByCustomerContainer}
    />

    <Route
      exact
      path="/total-servicos"
      component={ManagerCustomersByServiceContainer}
    />

    <Route
      exact
      path="/regioes-mais-pesquisadas"
      component={ManagerMostSearchedRegionsContainer}
    />

    <Route
      exact
      path="/ranking-avaliacoes"
      component={ManagerRatingRankingContainer}
    />

    <Route
      exact
      path="/editar-tesouro/:uuidTreasure"
      component={TreasureContainer}
    />
    <Route exact path="/tesouros" component={ManagerTreasureContainer} />
    <Route
      exact
      path="/cadastrar-comparativo"
      component={ComparativeContainer}
    />
    <Route exact path="/comparativos" component={ManagerComparativeContainer} />

    <Route exact path="/gerenciar-home-store" component={HomeStoreContainer} />
    <Route exact path="/mensagens" component={ManagerMessageContainer} />

    <Route
      exact
      path="/grupos-de-usuarios"
      component={ManagerCustomersGroupContainer}
    />
    <Route
      exact
      path="/cadastrar-administrador"
      component={AdministratorContainer}
    />
    <Route
      exact
      path="/editar-usuario/:uuidCustomer"
      component={CustomerContainer}
    />
    <Route
      exact
      path="/cadastro-grupo-de-usuarios"
      component={CustomersGroupContainer}
    />
    <Route
      exact
      path="/editar-grupo-de-usuarios/:customerGroupUuid"
      component={CustomersGroupContainer}
    />

    <Route
      exact
      path="/editar-categoria/:uuidCategory"
      component={CategoryContainer}
    />
    <Route
      exact
      path="/editar-aplicacao/:uuidApplication"
      component={ApplicationContainer}
    />
    <Route
      exact
      path="/editar-administrador/:uuidAdministrator"
      component={AdministratorContainer}
    />
    <Route
      exact
      path="/editar-comparativo/:uuidComparative"
      component={UpdateComparativeContainer}
    />
    {/* <Route exact path="/gerenciar-comentarios" component={QuestionManager} /> */}
    <Route exact path="/lista-de-precos" component={PriceListContainer} />

    <Route
      exact
      path="/editar-marca/:uuidBrand"
      component={BrandUpdateContainer}
    />

    <Route exact path="/usuarios" component={ManagerClientContainer} />
    <Route exact path="/propriedades" component={ManagerPropertiesContainer} />
    <Route exact path="/categorias" component={ManagerCategoryContainer} />
    <Route exact path="/aplicacoes" component={ManagerApplicationContainer} />
    <Route exact path="/administradores" component={ManagerAccountContainer} />
    <Route exact path="/marcas" component={ManagerBrandContainer} />
    <Route exact path="/" component={DashboardsContainer} />
    <Route exact path="/vendas/general" component={ManagerSalesContainer} />
    <Route exact path="/perguntas" component={QuestionManager} />
    <Route
      exact
      path="/perguntas/:uuidQuestions"
      component={ManagerTreasureQuestionsContainer}
    />
    <Route exact path="/avaliacoes" component={ManagerEvaluationsContainer} />
    <Route
      exact
      path="/avaliacoes/:uuidEvaluations"
      component={ManagerTreasureEvaluationsContainer}
    />
  </Switch>
);

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <LoginCenterPage {...props} />
      )
    }
  />
);
