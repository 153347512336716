import BaseAPI, { URLS } from './BaseAPI';

class MerchantAPI {
  /**Método util para retorno de erro */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} data - dados do novo Merchant que será salvo
   */
  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.MERCHANT, data);
      if (response.status === 201) return response;
      return { error: 'Erro inexperado ao cadastrar merchant' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar merchant');
    }
  }

  /**Busca Merchant pelo uuid */
  static async getMerchantByUUID(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.MERCHANT}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar merchant' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar merchant');
    }
  }

  /**Busca lista de merchants */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.MERCHANT, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao busca listas de merchant' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de merchants');
    }
  }
}
export default MerchantAPI;
