import { extendObservable, decorate, action } from "mobx";

import CategoryModel from "~/models/CategoryModel";
import CategoryAPI from "../services/CategoryAPI";
import { categoryTypes } from "~/helpers/utils/Selects";
import ProductModel from "~/models/ProductModel";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import UploadAPI from "../services/UploadAPI";
import FileModel from "~/models/FileModel";
import { CategoriesEnum } from "~/helpers/utils/Enums";

/**Valores inicias de variaveis observadas */
const initValues = {
  category: undefined,
  categories: [],
  loading: false,
  showCaseCategories: [],
  categorySelected: [],
  totalPages: 0,
  size: 20,
  page: 0,
};
class CategoryStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage) {
    const size = 20;
    this.page = numPage;
    this.pageFilter = numPage;
    this.size = size;
    await this.getTree();
    return true;
  }

  /**Reset values */
  reset() {
    this.category = undefined;
    this.categories = [];
  }

  /**Atualiza propriedades da categoria */
  updateProp(prop, value) {
    this.category[prop] = value;
  }

  /**Cria nova categoria com dados padrão */
  emptyCategory() {
    const type = categoryTypes[0].value;
    const merchant = this.rootStore.usersStore.userMerchant;
    this.category = new CategoryModel({
      type,
      merchant,
    });
  }

  /**Salva nova categoria. */
  async save() {
    this.loading = true;
    const list = [].concat(this.category.files);
    this.category.files = [];
    let data = new CategoryModel(this.category);
    data = JSON.stringify(data);
    const response = await CategoryAPI.save(data);
    this.loading = false;
    if (response.error) {
      if (
        response.error.includes("Order of the existing category!") &&
        response.status === 500
      ) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, "Ordenação já existente");
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    } else {
      this.emptyCategory();
      this.sendNewFiles(list, response.uuid);
      await this.getTree();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Categoria incluida com sucesso"
      );
    }
    return response;
  }

  /**Atualiza categoria categoria. */
  async update() {
    this.loading = true;
    const category = new CategoryModel(this.category);
    delete category.files;
    const data = JSON.stringify(category);
    const response = await CategoryAPI.update(this.category.uuid, data);
    this.loading = false;
    if (response.error) {
      if (
        response.error.includes("Order of the existing category!") &&
        response.status === 500
      ) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, "Ordenação já existente");
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    } else {
      await this.getTree();
      this.handleFiles(this.category.files);
      this.category = new CategoryModel(response);
      this.toastHelper.notify(STATUS_HELPER.INFO, "Dados atualizados");
    }
    return response;
  }

  /**Busca categorias em arvores*/
  async getTree(page = this.page) {
    this.loading = true;
    const response = await CategoryAPI.getTree(page);
    this.loading = false;
    if (!response.error) {
      this.categories = response.content.map((cat) => new CategoryModel(cat));
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  /**Busca categoria por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await CategoryAPI.get(uuid);
    if (!response.error) {
      this.category = new CategoryModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta categoria */
  async delete(uuid) {
    this.loading = true;
    const response = await CategoryAPI.delete(uuid);
    if (!response.error) this.toastHelper.notify("info", "Categoria removida");
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Envia arquivo de proposta que foi anexo */
  async handleFiles(files) {
    this.loading = true;

    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter((file) => file.uuid && file.file);
    const deleteds = files.filter((file) => file.uuid && !file.file);

    newFiles.length > 0 &&
      (await this.sendNewFiles(newFiles, this.category.uuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
    deleteds.length > 0 && (await this.deleteFiles(deleteds));

    this.loading = false;
    return true;
  }

  /**Funcão útil paradar feedbeack sobre envio de arquivos. */
  async filesService(promise, message) {
    const response = await Promise.all(promise);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFile(file.uuid);
    });
    return await this.filesService(promise, "Falha o deletar imagens");
  }

  /**Atualiza imagem da categoria */
  async updateFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.updateFile(file);
    });
    return true;
  }

  /**Envia imagem da categoria. */
  async sendNewFiles(files, categoryUuid) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadCategory(categoryUuid, file);
    });
    await this.filesService(promise, "ao incluir imagens");
    setTimeout(() => {
      files.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return true;
  }

  handleFileCategory(dropedFiles) {
    const category = this.category;
    this._handleImages(dropedFiles, "category", category.fileCategory);
  }

  handleIconCategory(dropedFiles) {
    const category = this.category;
    this._handleImages(dropedFiles, "icon-category", category.iconCategory);
  }

  /**Adiciona imagem ao produto */
  _handleImages(dropedFiles, tag, fileItem) {
    /**Altera arquivo. */
    const dropedFile = dropedFiles.length > 0 ? dropedFiles[0] : [];
    if (!fileItem && dropedFile) {
      const newFileItem = new FileModel({
        file: dropedFile,
        ...dropedFile,
        metaTags: [tag],
      });
      this.category.files.push(newFileItem);
    } else {
      fileItem.updateFile(dropedFile);
    }
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(excludes = {}) {
    if (!excludes) return;
    const localFilter = (category) => {
      const hasChildren = category.childrenCategory.length > 0;
      if (hasChildren) {
        category.childrenCategory =
          category.childrenCategory.filter(localFilter);
      }
      return !excludes.some((cat) => cat.uuid === category.uuid);
    };

    if (Array.isArray(excludes)) {
      return this.categories.filter(localFilter);
    }
    return this.categories.filter((cat) => cat.uuid !== excludes.uuid);
  }
}
decorate(CategoryStore, {
  get: action,
  getTree: action,
  save: action,
  update: action,
  delete: action,
  handleFiles: action,
});

export default CategoryStore;
