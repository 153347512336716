import BaseAPI, { URLS } from "./BaseAPI";

class MessageAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getTresureUuidQuestion(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.QUESTIONS}/${uuid}`);
      if (response.status === 200) return response.data;
      return console.log("Falha ao listar mensagens");
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista mensagens");
    }
  }

  /**
   * Aprovação de mensagem
   * @param  {string} uuid
   */
  static async approveQuestion(uuid) {
    try {
      const response = await BaseAPI.post(
        `${URLS.MANAGER}/questions/${uuid}/approve?approved=true&admin=ac3c9d13-463a-41b6-9904-15689dd2c44d`
      );
      if (response.status === 200) return response.data;
      return {
        error: "Não foi possível aprovar o mensagem",
      };
    } catch (e) {
      return this._exception(e, "Não foi possível aprovar o mensagem");
    }
  }

  /**
   * Request que lista Produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async getList(params = {}, filter) {
    const url = `${URLS.QUESTIONS}/find/${filter.value ? filter.value : "&"}`;

    try {
      const response = await BaseAPI.get(url, params);

      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar Tesouros" };
    } catch (e) {
      return this._exception(e, "Falha ao listar tesouros");
    }
  }

  /**
   * Aprovação de mensagem
   * @param  {string} uuid
   */
  static async editQuestionsTreasure(data) {
    const data2 = {
      question: data.question,
    };

    try {
      const response = await BaseAPI.put(
        `${URLS.QUESTIONS}/${data.uuid}`,
        data2
      );
      if (response.status === 200) return response.data;
      return {
        error: "Não foi possível aprovar o mensagem",
      };
    } catch (e) {
      return this._exception(e, "Não foi possível aprovar o mensagem");
    }
  }
}

export default MessageAPI;
