import BaseModel from "./BaseModel";
import CustomerModel from "./CustomerModel";
import TreasureModel from "./TreasureModel";

class QuestionsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.comment = data.comment;
    this.uuid = data.uuid;
    this.admin = data.admin;
    this.approved = data.approved;
    this.starsQty = data.starsQty;
    this.treasure = new TreasureModel(data.treasure);
    this.customer = new CustomerModel(data.customer);
  }
}

export default QuestionsModel;
