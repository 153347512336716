import BaseAPI, { URLS } from "~/services/BaseAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

/**
 * @function getProducts função para buscar os Tesouros
 * @param {String} filters filtros para busca de produto
 */

class TreasureAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que cria novo tesouro.
   * @param  {Object} data - Objeto do tesouro que será criado
   */
  static async saveTreasure(data) {
    try {
      const response = await BaseAPI.post(
        `${URLS.MANAGER}${URLS.TREASURE}`,
        data
      );
      if (response.status === 201) return response;
      return { error: "Erro inesperado ao cadastrar o tesouro" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar o tesouro");
    }
  }

  /**
   * Request que cria novo client.
   * @param  {Object} data - Objeto do usuário que será criado
   */
  static async saveClient(data) {
    try {
      const response = await BaseAPI.post(URLS.CUSTOMERS, data);
      if (response.status === 201) return response;
      return { error: "Erro inesperado ao cadastrar o usuário" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar o usuário");
    }
  }

  /**
   * Request que cria novo client.
   * @param  {Object} data - Objeto do usuário que será criado
   */
  static async updateClient(data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.CUSTOMERS}/${data.uuid}`,
        data
      );
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao cadastrar o usuário" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar o usuário");
    }
  }

  /**
   * API para aprovar um tesouro
   * @param  {Object} uuid - uuid do tesouro
   */
  static async approvedTreasure(uuid) {
    try {
      const response = await BaseAPI.post(
        `${URLS.MANAGER}${URLS.TREASURE}/${uuid}/approve?approved=true&hidden=false&admin=ac3c9d13-463a-41b6-9904-15689dd2c44d`
      );
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao aprovar um tesouro" };
    } catch (e) {
      return this._exception(e, "Falha ao aprovar um tesouro");
    }
  }

  /**
   * API para ocultar um tesouro
   * @param  {Object} uuid - uuid do tesouro
   */
  static async hiddenTreasure(uuid) {
    try {
      const response = await BaseAPI.post(
        `${URLS.MANAGER}${URLS.TREASURE}/${uuid}/approve?approved=true&hidden=true&admin=ac3c9d13-463a-41b6-9904-15689dd2c44d`
      );
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao ocultar um tesouro" };
    } catch (e) {
      return this._exception(e, "Falha ao ocultar um tesouro");
    }
  }
  /**Busca dados de um Tesouro */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.MANAGER}${URLS.TREASURE}/${uuid}`
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar usuários" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.MANAGER}${URLS.CUSTOMERS}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do usuário" };
    } catch (e) {
      console.log(e);
      return { error: "Falha ao atualizar informações do usuário" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async updateTreasure(data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.MANAGER}${URLS.TREASURE}/${data.uuid}`,
        data
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do tesouro" };
    } catch (e) {
      console.log(e);
      return { error: "Falha ao atualizar informações do tesouro" };
    }
  }

  /**
   * Request que lista Tesouros
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async getList(params) {
    const url = `/manager/treasures/find`;
    try {
      const response = await BaseAPI.get(url, params);

      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar Tesouros" };
    } catch (e) {
      return this._exception(e, "Falha ao listar tesouros");
    }
  }

  /**
   * Deleta o tesouro
   * @param  {string} uuid
   */
  static async delete(uuid) {
    try {
      const url = `${URLS.MANAGER}${URLS.TREASURE}/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return {
        error: "Não foi possível deletar o tesouro",
      };
    } catch (e) {
      return this._exception(e, "Não foi possível deletar o tesouro");
    }
  }

  /**
   * Deleta uma imagem do tesouro
   * @param  {string} uuid
   */
  static async deleteImage(uuid) {
    try {
      const url = `${URLS.MANAGER}/files/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 200) return response.data;
      return {
        error: "Não foi possível deletar a imagem",
      };
    } catch (e) {
      return this._exception(e, "Não foi possível deletar a imagem");
    }
  }
}

export default TreasureAPI;
