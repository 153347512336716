import React from "react";

/**
 * @Name: Título Padrão
 * @Data: 2019
 * @Desc: O Título Padrão do Sistema, nele cabe um subtitulo.
 * @props:
 *
 */

const TitleComponent = (props) => {
  // Recebe as propriedades
  const {
    title,
    subtitle,
    iconClass,
    type: pageTitle = false,
    clickIcon,
  } = props;

  return (
    <React.Fragment>
      <div
        className={`TitleComponent ${pageTitle ? "page-title" : ""}
            `}
      >
        <h1>
          <i className={iconClass} onClick={clickIcon}></i>
          {title}
        </h1>

        {subtitle && <p>{subtitle}</p>}
      </div>
    </React.Fragment>
  );
};

export default TitleComponent;
