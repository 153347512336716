import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

class CategoryModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.name = data.name;
    this.categoryName = data.categoryName;
    this.description = data.description;
    this.homeDisplay = data.homeDisplay;
    this.idCategory = data.idCategory;
    this.menuDisplay = data.menuDisplay;
    this.metaDescription = data.metaDescription;
    this.metaKeywords = data.metaKeywords;
    this.metaTitle = data.metaTitle;
    this.parentCategory = data.parentCategory;
    this.products = data.products;
    this.redirect = data.redirect;
    this.slug = data.slug;
    this.uuid = data.uuid;
    this.parentCategory = data.parentCategory;
    this.files = data.icon ? data.icon.map((file) => new FileModel(file)) : [];
    this.icon = data.icon ? data.icon.map((file) => new FileModel(file)) : [];
  }

  //Retorna um array com o arquivo do usuário, caso exista.
  get filesCategory() {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((tag) => tag === "category")
    );
  }

  get fileCategory() {
    return this.filesCategory[0];
  }

  get iconCategory() {
    return this.icon.find(
      (file) =>
        file.path && file.metaTags.some((tag) => tag === "icon-category")
    );
  }
}

export default CategoryModel;
