import { observer } from "mobx-react";
import React from "react";
import ReactPaginate from "react-paginate"; /**https://www.npmjs.com/package/react-paginate */

/**
 *  @Name: PaginationComponent
 * @Data: 2019
 * @Desc: Paginação de grupos e listas
 * @param  {} {onChange - callBack
 * @param  {} pages - numero de paginas
 * @param  {} initialPage - numero da pagina inicial (primeira pagina é 0)
 * @param  {} previousLabel - String que será o botão prev (por padrão "<")
 * @param  {} nextLabel - String que será o botão next (por padrão ">")
 * @param  {} classLink - classCss que será aplicado na tag a do item.
 * @param  {} classItem -class Css que será aplicado no item
 */
const PaginationComponent = (props) => {
  const {
    onChange,
    pages,
    initialPage = 0,
    previousLabel = "<",
    nextLabel = ">",
    classLink = "page-link",
    classItem = "page-item",
    forcePage,
    hidden = false,
  } = props;

  return pages > 1 && !hidden ? (
    <React.Fragment>
      <section className="PaginationComponent">
        <nav aria-label="Page navigation">
          <ReactPaginate
            forcePage={forcePage}
            disableInitialCallback={true}
            previousLabel={previousLabel}
            previousLinkClassName={classLink}
            nextLabel={nextLabel}
            nextLinkClassName={classLink}
            initialPage={initialPage}
            breakLabel={"..."}
            breakClassName={"break-me"}
            breakLinkClassName={classLink}
            pageLinkClassName={classLink}
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={onChange}
            containerClassName={"pagination justify-content-center"}
            subContainerClassName={classItem}
            pageClassName={classItem}
            activeClassName={"active"}
            activeLinkClassName={classLink}
          />
        </nav>
      </section>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default observer(PaginationComponent);
