import { action, decorate, extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import EvaluationsModel from "~/models/EvaluationsModel";
import FilterModel from "~/models/FilterModel";
import EvaluationsAPI from "~/services/EvaluationsAPI";

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  evaluations: [],
  evaluation: undefined,
};

class EvaluationsStore {
  totalPages = 0;
  page = 0;
  size = 20;
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.customerTypeStore.getList();
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 20;
    this.filter = "";
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList();
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, filterValue, valueInput) {
    const size = 20;
    this.page = numPage;
    this.pageFilter = numPage;
    this.size = size;
    await this.getList(filterValue, valueInput);
    return true;
  }

  async updateProp(prop, value) {
    const filter = this.filter ? this.filter : new FilterModel();
    filter[prop] = value;
    this.filter = new FilterModel(filter);
  }

  getTresureUuidEvaluation = async (uuid) => {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
    };
    const response = await EvaluationsAPI.getTresureUuidEvaluation(uuid);
    if (!response.error) {
      this.evaluations = await response.content.map(
        (prd) => new EvaluationsModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else console.log(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  };

  /**Busca todos os Tesouros Filtrados */
  async getList() {
    this.loading = true;

    const params = {
      state: this.filter?.state ? this.filter?.state.value : [],
      city: this.filter?.city ? this.filter?.city : [],
      document: this.filter?.cpf ? this.filter?.cpf : [],
      name: this.filter?.name ? this.filter?.name : [],
      approved: this.filter?.approved ? this.filter?.approved : [],
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    const response = await EvaluationsAPI.getList(params);
    if (!response.error) {
      this.evaluations = await response.content.map(
        (prd) => new EvaluationsModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else console.log(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta produto */
  async approveEvaluations(uuid, uuidTreasure) {
    this.loading = true;
    const response = await EvaluationsAPI.approveEvaluations(uuid);
    if (!response.error) {
      this.getList(uuidTreasure);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Comentário aprovado com sucesso"
      );
    } else console.log(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async updatePropTreasure(prop, value, data) {
    this.evaluation = new EvaluationsModel(data);
    this.evaluation[prop] = value;
  }

  // Aprovaçaõ de Tesouros
  async editEvaluationsTreasure() {
    this.loading = true;
    const response = await EvaluationsAPI.editEvaluationsTreasure(
      this.evaluation
    );
    if (!response.error) {
      this.treasure = new EvaluationsModel(response);
      this.getList();
    } else console.log(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }
}

export default EvaluationsStore;

decorate(EvaluationsStore, {
  getList: action,
  createEmptyProduct: action,
  searchByName: action,
});
