import BaseModel from './BaseModel';
import ProductModel from './ProductModel';
import OrderModel from './OrderModel';

class OrderItemModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.product = data.product
      ? new ProductModel(data.product)
      : undefined;
    this.order = data.order && new OrderModel(data.order);
    this.amount = data.amount;
    this.unitPrice = data.unitPrice;
    this.observation = data.observation || '';
  }
}

export default OrderItemModel;
