import BaseModel from './BaseModel';

class CustomerGroupModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this['@class'] = 'br.com.stoom.insumosholambra.model.InsumosHolambraCustomerType';
    this.uuid = data.uuid;
    this.name = data.name;
    this.code = data.code;
  }
}

export class CriteriaModel {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.criteria = data.criteria;
    this.value = data.value;
    this.customerUuid = data.customerUuid;
  }
}

export default CustomerGroupModel;
