import AdministratorStore from "./AdministratorStore";
import AuthStore from "./AuthStore";
import CategoryStore from "./CategoryStore";
import QuestionStore from "./QuestionStore";
import CustomerGroupStore from "./CustomerGroupStore";
import CustomerStore from "./CustomerStore";
import CustomerTypeStore from "./CustomerTypeStore";
import EvaluationsStore from "./EvaluationsStore";
import MenuStore from "./MenuStore";
import MerchantStore from "./MerchantStore";
import MessageStore from "./MessageStore";
import NotificationStore from "./NotificationStore";
import ProductStore from "./ProductStore";
import PropertiesStore from "./PropertiesStore";
import ReportsStore from "./ReportsStore";
import SalesStore from "./SalesStore";
import TreasureStore from "./TreasureStore";
import UsersStore from "./UsersStore";

/**
 * Rootstore guarda a instancia das stores.
 * Assim a comunicação das stores é feita atraves da rootStore.
 *
 * https://mobx.js.org/best/store.html
 */

class RootStore {
  _mobxStores = null;

  constructor(
    serializedStores = {
      usersStore: {},
      customerTypeStore: {},
      authStore: {},
      categoryStore: {},
      menuStore: {},
      productStore: {},
      questionStore: {},
      propertiesStore: {},
      notificationStore: {},
      administratorStore: {},
      customerStore: {},
      salesStore: {},
      reportsStore: {},
      evaluationsStore: {},
      messageStore: {},
      treasureStore: {},
      customerGroupStore: {},
      MerchantStore: {},
    }
  ) {
    if (this._mobxStores === null) {
      this.usersStore = new UsersStore(this, {
        ...serializedStores.usersStore,
      });

      this.customerTypeStore = new CustomerTypeStore(this, {
        ...serializedStores.customerTypeStore,
      });
      this.authStore = new AuthStore(this, {
        ...serializedStores.authStore,
      });
      this.categoryStore = new CategoryStore(this, {
        ...serializedStores.categoryStore,
      });
      this.menuStore = new MenuStore(this, {
        ...serializedStores.menuStore,
      });
      this.reportsStore = new ReportsStore(this, {
        ...serializedStores.reportsStore,
      });
      this.treasureStore = new TreasureStore(this, {
        ...serializedStores.treasureStore,
      });
      this.evaluationsStore = new EvaluationsStore(this, {
        ...serializedStores.evaluationsStore,
      });
      this.productStore = new ProductStore(this, {
        ...serializedStores.productStore,
      });
      this.questionStore = new QuestionStore(this, {
        ...serializedStores.questionStore,
      });
      this.propertiesStore = new PropertiesStore(this, {
        ...serializedStores.propertiesStore,
      });
      this.notificationStore = new NotificationStore(this, {
        ...serializedStores.notificationStore,
      });
      this.administratorStore = new AdministratorStore(this, {
        ...serializedStores.administratorStore,
      });
      this.customerStore = new CustomerStore(this, {
        ...serializedStores.customerStore,
      });
      this.salesStore = new SalesStore(this, {
        ...serializedStores.salesStore,
      });
      this.merchantStore = new MerchantStore(this, {
        ...serializedStores.merchantStore,
      });
      this.customerGroupStore = new CustomerGroupStore(this, {
        ...serializedStores.customerGroupStore,
      });
      this.messageStore = new MessageStore(this, {
        ...serializedStores.messageStore,
      });
    }
  }

  get mobxStores() {
    return (this._mobxStores = {
      usersStore: this.usersStore,
      customerTypeStore: this.customerTypeStore,
      authStore: this.authStore,
      categoryStore: this.categoryStore,
      menuStore: this.menuStore,
      productStore: this.productStore,
      questionStore: this.questionStore,
      propertiesStore: this.propertiesStore,
      merchantStore: this.merchantStore,
      evaluationsStore: this.evaluationsStore,
      notificationStore: this.notificationStore,
      administratorStore: this.administratorStore,
      reportsStore: this.reportsStore,
      customerStore: this.customerStore,
      messageStore: this.messageStore,
      salesStore: this.salesStore,
      treasureStore: this.treasureStore,
      customerGroupStore: this.customerGroupStore,
    });
  }
}

let store = null;

export function initializeStores(props) {
  if (store === null) {
    store = new RootStore(props);
  }
  return store;
}

export default RootStore;
