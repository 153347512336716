import React from "react";

/**
 * @Name: Item de Lista do Painel
 * @Data: 2019
 * @Desc: O Componente de lista (item)  dos cards
 * @props:
 *
 */

const ListPainelComponent = (props) => {
  // Recebe as propriedades
  const {
    name,
    date,
    onEdit,
    comment,
    approved,
    onDelete,
    children,
    state,
    onEditComment,
    group,
    document,
    mobile,
    email,
  } = props;

  return (
    <>
      <div className="ListPainelComponent">
        {children || (
          <>
            <div className="col-8">{comment}</div>
            {onEdit && (
              <div className="col-2">
                <button className={approved ? "aproved" : "not-aproved"}>
                  {approved ? "Aprovado" : "Pendente"}
                </button>
              </div>
            )}
            {onEditComment && (
              <div className="col-2">
                <i className="fas fa-edit" onClick={onEditComment} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ListPainelComponent;
