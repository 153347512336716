import BaseModel from "./BaseModel";

/**
 * Tipo de variação (ex: cor)
 */
class ReportsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.count = data.count;
    this.city = data.city;
    this.state = data.state;
    this.block = data.block;
    this.category = data.category;
    this.average = data.average;
    this.countTreasure = data.countTreasure;
    this.countCustomer = data.countCustomer;
  }
}

export default ReportsModel;
