import { action, decorate, extendObservable } from "mobx";

import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { onlyNumbers } from "~/helpers/utils/Functions";
import ClientModel from "~/models/ClientModel";
import FileModel from "~/models/FileModel";
import FilterModel from "~/models/FilterModel";
import TreasureModel from "~/models/TreasureModel";
import TreasureAPI from "~/services/TreasureAPI";
import UploadAPI from "~/services/UploadAPI";

class TreasureStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      treasures: [],
      client: undefined,
      treasure: undefined,
      loading: false,
      filter: undefined,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: "sentToValidation,Desc",
      deleteFileList: [],
    });
  }

  reset() {
    this.treasure = null;
    this.totalPages = 0;
    // this.page = 0;
    this.treasures = [];
    this.size = 10;
    this.sort = "sentToValidation,Desc";
    // this.filters = "";
    this.deleteFileList = [];
  }


  resetAllFilters() {
    this.treasure = null;
    this.totalPages = 0;
    this.page = 0;
    this.treasures = [];
    this.size = 10;
    this.sort = "sentToValidation,Desc";
    this.filters = "";
    this.deleteFileList = [];
  }
  /**Cria novo produto */
  createEmptyProduct() {
    this.treasure = new TreasureModel();
    this.client = new ClientModel();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  // async setPage(numPage) {
  //   const size = 10;
  //   this.page = numPage;
  //   this.pagefilters = numPage;
  //   this.size = size;
  //   await this.getList();
  //   return true;
  // }

  async setPage(page) {
    this.updateProp("page", page)
    await this.getList(this.size, page);
  }

  async setSort(sort) {

    if (sort.includes("customer.name")) {
      this.sort = "tc.name,Desc"
    } else if (sort.includes("customer.email")) {
      this.sort = "tc.email,Desc"
    } else if (sort.includes("address.state")) {
      this.sort = "a.state,Desc"
    } else if (sort.includes("address.city")) {
      this.sort = "a.city,Desc"
    } else if (sort.includes("document")) {
      this.sort = "tc.document,Desc"
    } else {
      this.sort = sort
    }

    await this.getList(this.size, this.page, this.sort);
  }

  async setFilters(filters = this.filters) {
    await this.getList(this.size, 0, this.sort, filters);
  }

  async exportCSV() {
    var filters = { ...this.filters, ...{ export: true } };

    const size = 10;
    const page = this.page;
    const sort = this.sort;

    this.loading = true;

    const params = {
      state: filters?.state ? filters.state.value : [],
      city: filters?.city ? filters.city : [],
      document: filters?.cpf ? filters.cpf : [],
      name: filters?.name ? filters.name : [],
      email: filters?.email ? filters.email : [],
      approved:
        filters?.approved === true || filters?.approved === false
          ? filters.approved
          : [],
      hidden: filters?.hidden ? filters.hidden : [],
      export: filters?.export ? filters.export : [],
      page: page,
      size: size,
      sort: sort,
    };

    const response = await TreasureAPI.getList(params);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async updatePropTreasure(prop, value) {
    const treasure = this.treasure ? this.treasure : new TreasureModel();
    switch (prop) {
      case "name":
        treasure.customer[prop] = value;
        break;
      case "email":
        treasure.customer[prop] = value;
        break;
      case "document":
        treasure.customer[prop] = value;
        break;
      case "birthdate":
        treasure.customer[prop] = value;
        break;
      case "password":
        treasure.customer[prop] = value;
        break;
      case "zipCode":
        treasure.address[prop] = onlyNumbers(value);
        break;
      case "city":
        treasure.address[prop] = value;
        break;
      case "street":
        treasure.address[prop] = value;
        break;
      case "state":
        treasure.address[prop] = value;
        break;
      case "block":
        treasure.address[prop] = value;
        break;
      case "uuid":
        treasure.category[prop] = value;
        break;
      default:
        treasure[prop] = value;
    }
  }

  async updateProp(prop, value) {

    const filters = this.filters ? this.filters : new FilterModel();
    filters[prop] = value;
    this.filters = new FilterModel(filters);

  }

  async saveTreasure() {
    this.loading = true;
    const dataClient = JSON.stringify(this.treasure.customer);
    const treasure = this.treasure;

    const filesTreasure = this.treasure.files;
    const filesCustomer = this.treasure.customer.files;

    let response = await TreasureAPI.saveClient(dataClient);

    if (!response.error) {
      const client = new ClientModel(response.data);
      treasure["customer"] = client.uuid;
      const dataTreasure = JSON.stringify(this.treasure);
      let responseTresure = await TreasureAPI.saveTreasure(dataTreasure);
      const tresureResponse = new TreasureModel(responseTresure.data);
      if (!responseTresure.error) {
        this.sendNewFilesTreasure(filesTreasure, tresureResponse.uuid);
        this.sendNewFilesCustomer(filesCustomer, tresureResponse?.customer?.uuid);
        this.toastHelper.notify(STATUS_HELPER.INFO, "Tesouro Cadastrado");
        this.loading = false;
        return responseTresure;

      } else {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          "Não foi possível cadastrar o tesouro"
        );
        this.loading = false;
        return responseTresure;
      }
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Não foi possível cadastrar o usuário"
      );
      this.loading = false;
      return { error: "Não foi possível cadastrar o usuário" };
    }
  }

  /**Busca todos os Tesouros Filtrado*/
  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;

    const params = {
      state: filters?.state ? filters.state : [],
      city: filters?.city ? filters.city : [],
      document: filters?.cpf ? filters.cpf : [],
      name: filters?.name ? filters.name : [],
      email: filters?.email ? filters.email : [],
      code: filters?.code ? filters.code : [],
      approved:
        filters?.approved === true || filters?.approved === false
          ? filters.approved
          : [],
      hidden: filters?.hidden === true || filters?.hidden === false ? filters.hidden : [],
      page: page,
      size: size,
      sort: sort,
    };

    const response = await TreasureAPI.getList(params);
    if (!response.error) {
      this.treasures = await response.content.map(
        (prd) => new TreasureModel(prd)
      );

      this.totalPages = response.totalPages;
      this.page = response.number;
      this.size = response.size;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  //update Treasure
  async update() {
    this.loading = true;
    const treasure = this.treasure;
    const treasureFiles = this.treasure.files;
    const customerFiles = this.treasure.customer.files;
    if (Object.keys(this.treasure.category).length === 0) {
      this.treasure.category = null;
    }

    let response = await TreasureAPI.updateClient(this.treasure.customer);

    if (!response.error) {
      treasure["customer"] = this.treasure.customer.uuid;
      let responseTresure = await TreasureAPI.updateTreasure(treasure);
      await this.sendFiles(
        treasure.uuid,
        treasure.customer,
        treasureFiles,
        customerFiles
      );
      if (!responseTresure.error) {
        this.toastHelper.notify(STATUS_HELPER.INFO, "Tesouro Cadastrado");
      }
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Não foi possível editar o tesouro"
      );
    }
    this.loading = false;
    return response;
  }

  // Funções para salvar e atualizar Imagem
  async sendFiles(treasureUuid, customerUuid, treasureFiles, customerFile) {
    const newFilesTreasure = treasureFiles.filter((file) => !file.uuid);
    const newFilesCustomer = customerFile.filter((file) => !file.uuid);
    // const updateds = treasureFiles.filter((file) => file.uuid);

    newFilesTreasure.length > 0 &&
      (await this.sendNewFilesTreasure(newFilesTreasure, treasureUuid));

    newFilesCustomer.length > 0 &&
      (await this.sendNewFilesCustomer(newFilesCustomer, customerUuid));

    // updateds.length > 0 && (await this.updateFiles(updateds));
    this.deleteFileList.length > 0 &&
      (await this.deleteFiles(this.deleteFileList));
  }

  async updateFiles(updatedFiles, treasureUuid) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      "Falha atualizar arquivos."
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  async sendNewFilesTreasure(newFiles, treasureUuid) {
    const promiseNew = newFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadTreasure(treasureUuid, file);
    });
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  async sendNewFilesCustomer(newFiles, customerUuid) {
    const promiseNew = newFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadCustomer(customerUuid, file);
    });
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFile(file.uuid);
    });
    return await this.handlePromises(promise, "Falha o deletar arquivo");
  }

  // Traz os dados de um tesouro por UUID
  async get(uuid) {
    this.loading = true;
    const response = await TreasureAPI.get(uuid);
    if (!response.error) {
      this.treasure = new TreasureModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  // Aprovaçaõ de Tesouros
  async approvedTreasure(uuid, list) {
    this.loading = true;
    const response = await TreasureAPI.approvedTreasure(uuid);
    if (!response.error) {
      this.treasure = new TreasureModel(response);
      if (list) {
        this.getList();
      } else {
        this.get(uuid);
      }
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  // Aprovaçaõ de Tesouros
  async hiddenTreasure(uuid, list) {
    this.loading = true;
    const response = await TreasureAPI.hiddenTreasure(uuid);
    if (!response.error) {
      this.treasure = new TreasureModel(response);
      if (list) {
        this.getList();
      } else {
        this.get(uuid);
      }
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta o tesouro */
  async delete(uuid) {
    this.loading = true;
    const response = await TreasureAPI.delete(uuid);
    if (!response.error) {
      this.getList();
      this.toastHelper.notify(STATUS_HELPER.INFO, "Tesouro deletado");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  /**Deleta a imagem do tesouro */
  async deleteImage(uuid, treasureUuid) {
    this.loading = true;
    const response = await TreasureAPI.deleteImage(uuid);
    if (!response.error) {
      this.get(treasureUuid);
      this.toastHelper.notify(STATUS_HELPER.INFO, "Imagem Deletada");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  /**Adiciona imagem ao produto */
  handleAddTreasureImage(files) {
    if (!this.treasure) this.createEmptyProduct();

    for (let index = 0; index < files.length; index++) {
      const obj = files[index];
      const { file, description } = obj;

      const ordering =
        this.treasure.files.length > 0 ? this.treasure.files.length - 1 : 0;

      const newFileItem = new FileModel({
        file,
        ...file,
        metaTags: [description],
        size: 1,
        ordering,
      });
      this.treasure.files.push(newFileItem);
    }
  }

  handleAddCustomerImage(files) {
    if (!this.treasure) this.createEmptyProduct();

    for (let index = 0; index < files.length; index++) {
      const obj = files[index];
      const file = obj;

      const ordering =
        this.treasure.customer.files.length > 0
          ? this.treasure.customer.files.length - 1
          : 0;

      const newFileItem = new FileModel({
        file,
        ...file,
        metaTags: [],
        size: 1,
        ordering,
      });
      this.treasure.customer.files.push(newFileItem);
    }
  }

  handleDeleteTreasureImage(fileRemove) {
    this.treasure.files = this.treasure.files.filter(
      (file) => file != fileRemove
    );

    if (fileRemove?.uuid) this.deleteFileList.push(fileRemove);
    return this.treasure.files;
  }

  setTrasureFiles(files) {
    this.treasure.files = files;
  }

  handleDeleteCustomerImage(fileRemove) {
    this.treasure.customer.files = [];

    if (fileRemove?.uuid) this.deleteFileList.push(fileRemove);
    return [];
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}

export default TreasureStore;

decorate(TreasureStore, {
  getList: action,
  setPage: action,
  setSort: action,
  exportCSV: action,
});
