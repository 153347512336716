import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import CustomerGroupModel from "~/models/CustomerGroupModel";
import CustomerGroupAPI from "~/services/CustomerGroupAPI";

const initValues = {
  loading: false,
  customerGroup: new CustomerGroupModel(),
  customerGroups: [],
  optionsCustomersGroup: [],
  criterias: [],
  groupCustomer: [],
  groupCustomerItem: null,
  criteriaOptions: [],
  campaignsAndVouchers: [],
};

class CustomerGroupStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = "created,desc";
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList();
    return this.totalPages;
  }

  /**Atualiza propriedades da categoria */
  updateProp(prop, value) {
    const customerGroup = this.customerGroup
      ? this.customerGroup
      : new CustomerGroupModel(value);
    customerGroup[prop] = value;
    this.customerGroup = new CustomerGroupModel(customerGroup);
  }

  /**Salva um novo grupo de usuário */
  async onSave() {
    this.loading = true;
    const response = await CustomerGroupAPI.save(this.customerGroup);
    this.loading = false;
    if (!response.error) {
      this.customerGroup = response.data;
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Grupo de usuário criado com sucesso!"
      );
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Erro ao adicionar o grupo de usuário"
    );
    return false;
  }

  /**Atualiza um grupo de usuário */
  async onUpdate() {
    this.loading = true;
    const uuid = this.customerGroup.uuid;

    const response = await CustomerGroupAPI.update(this.customerGroup, uuid);
    this.loading = false;
    if (!response.error) {
      this.customerGroup = response.data;
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Grupo de usuário atualizado com sucesso!"
      );
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Erro ao atualizar o grupo de usuário"
    );
    return false;
  }

  /**Busca lista de vendedores */
  async getList() {
    this.loading = true;
    const params = {
      size: this.size,
      page: this.page,
      sort: this.sort,
      name: this.filter,
    };
    const response = await CustomerGroupAPI.getList(params);

    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.customerGroups = response.content.map(
      (c) => new CustomerGroupModel(c)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getListSelected(params) {
    const optionsCustomersGroup = [];
    const response = await CustomerGroupAPI.getList(params);
    if (!response.error) {
      response.content.map((c) => {
        optionsCustomersGroup.push({ label: c.name, value: c.code });
      });
      this.optionsCustomersGroup = optionsCustomersGroup;
      return optionsCustomersGroup;
    }
    return false;
  }

  reset() {
    this.customerGroup = new CustomerGroupModel();
    this.criterias = [];
    this.campaignsAndVouchers = [];
  }

  handleDeleteCriteria(element) {
    let { criterias } = this;

    if (element.customerUuid) {
      criterias = criterias.filter(
        (c) => c.customerUuid !== element.customerUuid
      );
    } else if (element.uuid) {
      criterias = criterias.filter((c) => c.uuid !== element.uuid);
    } else {
      criterias = criterias.filter(
        (c) => c.value !== element.value || c.criteria !== element.criteria
      );
    }

    this.criterias = criterias;
  }

  async getCustomerByName(firstName) {
    const params = {
      firstName,
    };

    const response = await CustomerGroupAPI.getCustomerByName(params);

    if (!response.error) {
      const optionsCustomersGroup = [];
      if (response.content && response.content.length > 0) {
        response.content.map((b) => {
          const responseFilter = this.criterias.filter(
            (c) => c?.customerUuid === b.uuid
          );

          if (responseFilter.length === 0) {
            optionsCustomersGroup.push({
              value: b.uuid,
              label: b.firstName + b.lastName,
            });
          }
          return false;
        });
      }
      this.optionsCustomersGroup = optionsCustomersGroup;
      return optionsCustomersGroup;
    }

    return [];
  }

  /**Função para deletar grupo de usuário */
  async onDelete(customerGroupUuid) {
    const response = await CustomerGroupAPI.delete(customerGroupUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Grupo de usuário deletado com sucesso"
      );
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Falha ao deletar o grupo de usuário"
    );
  }

  /**Funcao para mudar status do grupo de usuário */
  async onChangeStatus(customerGroupUuid) {
    const response = await CustomerGroupAPI.statusChange(customerGroupUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Status atualizado com sucesso"
      );
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Falha ao atualizar o grupo de usuário"
    );
  }

  /**Função para buscar um grupo de usuário para ser usada da tela de update */
  async getByUuid(customerGroupUuid) {
    const response = await CustomerGroupAPI.getCustomerGroupByUuid(
      customerGroupUuid
    );
    if (!response.error) {
      this.customerGroup = response;
      return this.customerGroup;
    }

    return false;
  }

  async searchByName(name) {
    const params = {
      name: name,
      page: 0,
      sort: "name",
    };
    const response = await CustomerGroupAPI.getList(params);
    if (!response.error) {
      this.customerGroups = response.content.map(
        (item) => new CustomerGroupModel(item)
      );
      return this.getListSelect();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  getListSelect(customerGroups = this.customerGroups) {
    return customerGroups.map((cg) => ({
      value: cg.uuid,
      label: cg.name,
    }));
  }
}

export default CustomerGroupStore;
