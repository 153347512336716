import BaseModel from "./BaseModel";

class AddressModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.street = data.street;
    this.number = data.number;
    this.block = data.block;
    this.addressComplement = data.addressComplement;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country || "BRASIL";
    this.refAddress = data.refAddress;
    this.addressComplement = data.addressComplement;
    this.uuid = data.uuid;
  }

  get isValid() {
    if (!this.zipCode) return false;
    if (!this.street) return false;
    if (!this.city) return false;
    if (!this.block) return false;
    if (!this.number) return false;
    if (!this.state) return false;
    return true;
  }
}

export default AddressModel;
