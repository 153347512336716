import BaseAPI from '~/services/BaseAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

const ListPriceAPI = {
  getProducts: async (filters, page = 0, branch, sort = 'created,desc') => {
    try {
      const { data: response } = await BaseAPI.get('manager/prices', {
        ...filters,
        size: 10,
        branch,
        page,
        sort,
      });
      return response;
    } catch (error) {
      const toast = new ToastHelper();

      toast.notify(STATUS_HELPER.ERROR, 'Estoque de produtos não encontrados');
    }
  },
};

export default ListPriceAPI;
