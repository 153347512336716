import { action, decorate, extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import ClientModel, {
  BIRTHDATE_ATTRIBUTE_CONFIG_UUID,
} from "~/models/ClientModel";
import FilterModel from "~/models/FilterModel";
import CustomerAPI from "../services/CustomerAPI";

class CustomerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      customer: undefined,
      customers: [],
      city: [],
      loading: false,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "firstName",
      filter: undefined,
    });
  }

  reset() {
    this.customer = undefined;
    this.customers = [];
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "firstName";
    this.filter = "";
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList();
    return true;
  }

  async updateProp(prop, value) {
    const filter = this.filter ? this.filter : new FilterModel();
    filter[prop] = value;
    this.filter = new FilterModel(filter);

    console.log(this.filter);
  }

  async updatePropClient(prop, value) {
    const customer = this.customer ? this.customer : new ClientModel();
    if (prop == "birthdate") {
      var obj = {
        attributeConfig: { uuid: BIRTHDATE_ATTRIBUTE_CONFIG_UUID },
        attributeValue: {
          value: value,
        },
      };
      if (customer["attributes"].length > 0) customer["attributes"][0] = obj;
      else customer["attributes"].push(obj);
    } else customer[prop] = value;
    this.customer = new ClientModel(customer);
  }

  async exportCSV() {
    this.loading = true;

    const params = {
      document: this.filter?.cpf ? this.filter?.cpf : [],
      name: this.filter?.name ? this.filter?.name : [],
      email: this.filter?.email ? this.filter?.email : [],
      page: this.page,
      export: true,
      size: this.size,
    };

    const response = await CustomerAPI.getList(params);

    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    return response;
  }

  /**Busca lista de usuários */
  async getList() {
    this.loading = true;

    const params = {
      document: this.filter?.cpf ? this.filter?.cpf : [],
      name: this.filter?.name ? this.filter?.name : [],
      email: this.filter?.email ? this.filter?.email : [],
      page: this.page,
      size: this.size,
    };

    const response = await CustomerAPI.getList(params);

    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.customers = response.content.map((usr) => new ClientModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**
   * *Busca todas as informações de um usuário customer
   * @param  {} userName
   */
  async get(uuid) {
    this.loading = true;
    const response = await CustomerAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.customer = new ClientModel(response);
      return this.customer;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Cria um novo usuário (usuario) */
  async saveClient() {
    this.loading = true;
    const data = JSON.stringify({ ...this.customer, ...{ terms: true } });

    const response = await CustomerAPI.saveClient(data);
    if (!response.error) {
      this.customer = new ClientModel(response);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Usuário criado com sucesso."
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Atualiza informações do usuário */
  async updateClient() {
    this.loading = true;
    const data = JSON.stringify(this.customer);
    const response = await CustomerAPI.update(this.customer.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Informações atualizadas com sucesso."
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta o usuário */
  async delete(uuid) {
    this.loading = true;
    const response = await CustomerAPI.delete(uuid);
    if (!response?.error) {
      this.getList();
      this.toastHelper.notify(STATUS_HELPER.INFO, "Usuário deletado");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }
}

export default CustomerStore;

decorate(CustomerStore, {
  create: action,
  update: action,
  get: action,
  getByQuery: action,
  getPending: action,
});
