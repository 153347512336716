import BaseModel from "./BaseModel";

/**
 * Variações (Ex: Azul, vermelhor, amarelo)
 */
class FilterModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.state = data.state;
    this.category = data.category;
    this.type = data.type;
    this.city = data.city;
    this.cpf = data.cpf;
    this.name = data.name;
    this.email = data.email;
    this.approved = data.approved;
    this.hidden = data.hidden;
    this.neighborhood = data.neighborhood;
    this.code = data.code;
    this.page = data.page;
    this.sort = data.sort;
  }
}
export default FilterModel;
