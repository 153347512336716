import BaseModel from "./BaseModel";

class AdministratorModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.idAdministrator = data.idAdministrator;
    this.name = data.name;
    this.email = data.email;
    this.password = data.password;
    this.tokenSocial = data.tokenSocial;
    this.uuid = data.uuid;
    this.newPassword = data.newPassword;
  }
}

export default AdministratorModel;
