//import { dateToText } from "~/helpers/utils/Functions";

export default class BaseModel {
  constructor(data = {}) {
    if (!data) return;
    this.uuid = data.uuid;
    this.externalId = data.externalId;
    this.created = data.created;
    this.modified = data.modified;
  }
}
