import BaseModel from '../BaseModel';

class ProductOrigensModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.active = data.active;
    this.code = data.code;
    this.description = data.description;
    this.uuid = data.uuid;
  }

}

export default ProductOrigensModel;
