import BaseAPI, { URLS, baseURL } from "./BaseAPI";
import axios from "axios";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";

class UploadAPI {
  /**Atualiza imagem */
  static async updateFile(file) {
    try {
      const url = `${URLS.MANAGER}${URLS.FILES}/${file.uuid}`;
      const response = await this._updateFiles(url, file);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Atualiza imagem */
  static async getProductsCSV(file) {
    try {
      const url = `${URLS.MANAGER}/products/export-csv`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Checa Csv Antes de enviar*/
  static async checkCSV(file) {
    try {
      const url = `${URLS.MANAGER}/products/check-csv`;
      const response = await this._updateCSV(url, file);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Atualiza CSV */
  static async sendCSV(file) {
    try {
      const url = `${URLS.MANAGER}/products/update-csv`;
      const response = await this._updateCSVPut(url, file);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Upload de arquivos */
  static async _updateCSV(endpoint, uploadItem) {
    const form = new FormData();
    form.append("file", uploadItem);
    const data = form;
    const config = getConfig(uploadItem);
    return await axios.post(`${baseURL()}${endpoint}`, data, config);
  }

  /**Upload de arquivos */
  static async _updateCSVPut(endpoint, uploadItem) {
    const form = new FormData();
    form.append("file", uploadItem);
    const data = form;
    const config = getConfig(uploadItem);
    return await axios.put(`${baseURL()}${endpoint}`, data, config);
  }

  /*
   *Upload de produtos
   */
  static async uploadTreasure(uuid, uploadItem) {
    try {
      const url = `/manager/files/treasures/${uuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  static async uploadCustomer(uuid, uploadItem) {
    try {
      const url = `/manager/files/customers/${uuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   *Upload de produtos
   */
  static async uploadLogoSupplier(supplierUuid, uploadItem) {
    try {
      const url = `${URLS.SUPPLIER}/${supplierUuid}${URLS.FILES}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   * Upload de imagem da categoria
   */
  static async uploadCategory(categoryUuid, uploadItem) {
    try {
      const url = `${URLS.CATEGORIES_FILES}/${categoryUuid}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
    * Upload de imagem de propriedade
    properties/values/{uuid}/file
    */
  static async uploadProperty(propertyUuid, uploadItem) {
    try {
      const url = `${URLS.PROPERTY}/values/${propertyUuid}/file`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /*
   * Upload de proposta de pedido
   */
  static async uploadOrder(orderUuid, uploadItem) {
    try {
      const url = `${URLS.MANAGER}${URLS.ORDERS}/${orderUuid}${URLS.FILES}`;
      const response = await this._uploadFiles(url, uploadItem);
      if (response.status === 200) return response;
      else return { error: "Não foi possível enviar o arquivo!" };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Remove arquivo*/
  static async removeFile(uuid) {
    const url = `${baseURL()}/files/${uuid}`;
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
        "Content-Type": "multipart/form-data",
      },
    };
    return await axios.delete(url, config);
  }

  /**Upload de arquivos */
  static async _updateFiles(endpoint, uploadItem) {
    const data = uploadItem.getFormData();
    const config = getConfig(uploadItem);
    return await axios.put(`${baseURL()}${endpoint}`, data, config);
  }

  /**Upload de arquivos */
  static async _uploadFiles(endpoint, uploadItem) {
    const data = uploadItem.getFormData();
    const config = getConfig(uploadItem);
    return await axios.post(`${baseURL()}${endpoint}`, data, config);
  }

  /**
   * Request que faz upload de imagem do banner
   * @param  {Object} file - Objeto com a imagem
   * @param  {String} bannerUuid - Id do banner
   */
  static async sendNewFileBanner(bannerUuid, bannerFile, dataFile) {
    try {
      const url = `${URLS.MANAGER}/banners/${bannerUuid}/files`;
      const response = await this._uploadFilesBanner(url, bannerFile, dataFile);
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao fazer upload da imagem" };
    } catch (e) {
      return this._exception(e, "Falha ao fazer upload da imagem");
    }
  }

  /**Upload de arquivos para o banner */
  static async _uploadFilesBanner(endpoint, bannerFile, dataFile) {
    const form = new FormData();
    form.append("file", dataFile[0]);
    form.append(
      "bannerFile",
      new Blob([JSON.stringify(bannerFile)], { type: "application/json" }),
      "bannerFile"
    );

    const config = getConfigBanner(dataFile[0]);
    return await axios.post(`${baseURL()}${endpoint}`, form, config);
  }
}

const getConfig = (uploadItem) => ({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
    "Content-Type": "multipart/form-data",
  },
  onUploadProgress: (progressEvent) =>
    (uploadItem.percentCompleted = parseInt(
      Math.floor((progressEvent.loaded * 100) / progressEvent.total)
    )),
});

const getConfigBanner = (uploadItem) => ({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
    "Content-Type": "application/json",
  },
  onUploadProgress: (progressEvent) =>
    (uploadItem.percentCompleted = parseInt(
      Math.floor((progressEvent.loaded * 100) / progressEvent.total)
    )),
});

export default UploadAPI;
