import AddressModel from "./AddressModel";
import BaseModel from "./BaseModel";
import FileModel from "./FileModel";
import CategoryModel from "./CategoryModel";
import CustomerModel from "./CustomerModel";

export default class TreasureModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.code = data.code;
    this.approved = data.approved;
    this.created = data.created;
    this.deleted = data.deleted;
    this.description = data.description;
    this.facebookUrl = data.facebookUrl;
    this.instagramUrl = data.instagramUrl;
    this.modified = data.modified;
    this.sentToValidation = data.sentToValidation;
    this.category = data.category;
    this.phone = data.phone;
    this.showAddress = data.showAddress;
    this.category = new CategoryModel(data.category);
    this.showPhone = data.showPhone;
    this.siteUrl = data.siteUrl;
    this.customer = new CustomerModel(data.customer);
    this.title = data.title;
    this.uuid = data.uuid;
    this.address = new AddressModel(data.address);
    this.files = data.photos
      ? data.photos.map((file) => new FileModel(file))
      : [];
    this.hidden = data.hidden;
    this.status = data.hidden ? "Oculto" : data.approved ? "Ativo" : "Inativo";
  }

  get document() {
    return this.customer.documentNormalized && this.customer.document;
  }

  getFile(tag) {}

  getFiles(tag) {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  getFirstFile() {
    return this.files.length > 0 ? this.files[0] : null;
  }

  getOthersFiles() {
    return this.files.length > 0 ? this.files.slice(1) : null;
  }
}
