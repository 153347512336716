import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FaUnlink } from 'react-icons/fa';
import FileSaver from 'file-saver';

import CardPainelComponent from '~/components/CardPainelComponent/CardPainelComponent';
import ListPainelComponent from '~/components/ListPainelComponent/ListPainelComponent';
import PaginationComponent from '~/components/PaginationComponent/PaginationComponent';
import HeaderComponent from './HeaderComponent';
import { dateToText } from '~/helpers/utils/Functions';
import SearchComponent from '~/components/SearchComponent';
import ButtonComponent from '~/components/ButtonComponent/ButtonComponent';
import ButtonUpdateProductImages from '../../components/ButtonUpdateProductImages/ButtonUpdateProductImages';
import ListPriceComponent from './ListPriceComponent';
import SelectComponent from '~/components/SelectComponent/SelectComponent';
import ListPriceAPI from '~/services/ListPrice';

// import LoadingInformationsComponent from '../../components/LoadingInformationsComponent';

class ManagerProductsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.productStore = props.productStore;
    this.propertiesStore = props.propertiesStore;
    this.menuStore = props.menuStore;
    this.searchData = {};
    this.listPrice = null;
    this.state = {
      optionPriceState: { value: 'notFilter', label: 'Mostrar todos' },
      attributeName: '',
      attributeValue: '',
      attributes: [],
    };
  }

  componentDidMount() {
    this.productStore.reset();
    const { size = 10 } = this.props;

  }

  componentWillUnmount() {
    this.productStore.reset();
  }

  render() {
    const {
      large = true,
      title = 'Gerenciamento Lista de preços',
      subtitle = 'Listagem de preços',
      size = 10,
      paginate = true,
      dashboard = false,
    } = this.props;

    return (
      <>
        <ListPriceComponent />
      </>
    );
  }
}

export default inject(
  'productStore',
  'propertiesStore',
  'menuStore'
)(withRouter(observer(ManagerProductsContainer)));
