import React from "react";
import HeaderListComponent from "../../components/HeaderListComponent";

const HeaderComponent = ({ onClick, currentSort }) => {
  const sort = (value) => {
    if (currentSort.includes(value)) {
      if (currentSort === `${value},desc`) {
        onClick(value);
      } else {
        onClick(`${value},desc`);
      }
    } else {
      onClick(value);
    }
  };

  const items = () => {
    return [
      { size: "8", callback: (e) => sort("name"), label: "Perguntas" },
      { size: "2", callback: (e) => sort(""), label: "Status" },
      { size: "2", callback: (e) => sort(""), label: "" },
    ];
  };

  return <HeaderListComponent items={items()} />;
};

export default HeaderComponent;
