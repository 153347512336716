import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import CollumComponent from "~/components/CollumComponent/CollumComponent";
import FormGroupComponent from "~/components/FormGroupComponent/FormGroupComponent";
import LabelComponent from "~/components/LabelComponent/LabelComponent";
import ModalComponent from "~/components/ModalComponent";
import ModalConfirmationComponent from "~/components/ModalConfirmationComponent/ModalConfirmationComponent";
import TextareaComponent from "~/components/TextareaComponent";
import TitlePageComponent from "~/components/TitlePageComponent/TitlePageComponent";
import { ModalIdsEnum } from "~/helpers/utils/Enums";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import CardPainelComponent from "../../components/CardPainelComponent/CardPainelComponent";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import HeaderComponent from "./HeaderComponent";
import ListPainelComponent from "./ListPainelComponent/ListPainelComponent";

class ManagerTreasureQuestionsContainer extends React.Component {
  uuidQuestions = "";

  constructor(props) {
    super(props);
    this.questionStore = props.questionStore;

    this.state = {
      managerUUID: "",
      managerName: "",
      modalResponse: "",
    };
  }

  componentDidMount() {
    this.setTreasure();
  }

  componentDidUpdate() {
    this.setTreasure();
  }

  async setTreasure() {
    const nextUuid = this.props.match.params.uuidQuestions;

    if (this.uuidQuestions !== nextUuid) {
      this.uuidQuestions = nextUuid;
      if (nextUuid) {
        await this.questionStore.getTresureUuidQuestion(this.uuidQuestions);
      } else {
        this.isEditProduct = false;
      }
    }
  }

  openModal = async (e, managerUUID, managerName) => {
    this.setState({ managerUUID: managerUUID, managerName: managerName });
    window.$(`#${ModalIdsEnum.APPROVED_QUESTION}`).modal("show");
  };

  approveQuestion = async (e) => {
    this.questionStore.approveQuestion(
      this.state.managerUUID,
      this.uuidQuestions
    );
    window.$(`#${ModalIdsEnum.APPROVED_QUESTION}`).modal("hide");
  };

  onEdit(e, uuid) {
    e.preventDefault();
    this.props.history.push(`/editar-tesouro/${uuid}`);
  }

  onClick = (e) => {
    this.props.menuStore.setCurrentMenu("/cadastrar-tesouros");
    this.props.history.push("/cadastrar-tesouros");
  };

  openModalEditQuestion = async (e, item) => {
    this.setState({ modalResponse: item });
    window.$(`#${ModalIdsEnum.EDIT_QUESTION}`).modal("show");
  };

  /**Pega os valores do componente select e grava no modelo da store */
  handleChange = (prop, values) => {
    this.questionStore.updatePropTreasure(
      prop,
      values,
      this.state.modalResponse
    );
  };

  editQuestion = () => {
    this.questionStore.editQuestionsTreasure();
  };

  onClick = (e) => {
    this.props.menuStore.setCurrentMenu("/perguntas");
    this.props.history.push("/perguntas");
  };

  render() {
    const { totalPages, page, loading, questions } = {
      ...this.props.questionStore,
    };

    const name = StorageUtil.getItem(KEYS.TREASURE_NAME);

    return (
      <React.Fragment>
        {!this.props.treasureScreen && (
          <TitlePageComponent
            title={`Perguntas de ${name}`}
            clickButton={{ click: this.onClick }}
            buttonTitle="Listagem"
          />
        )}

        <CardPainelComponent loading={loading} large title="Lista perguntas">
          <section>
            <HeaderComponent onClick={this.changeSort} />
            {questions &&
              questions.map((item, index) => (
                <ListPainelComponent
                  key={index}
                  comment={item.comment}
                  approved={item.approved}
                  onEdit={(e) => this.openModal(e, item.uuid, item.comment)}
                  onEditQuestion={(e) => this.openModalEditQuestion(e, item)}
                />
              ))}
          </section>
          <PaginationComponent
            pages={totalPages}
            onChange={(selectedItem) =>
              this.props.treasureStore.setPage(selectedItem.selected)
            }
            initialPage={page}
          />
        </CardPainelComponent>
        <ModalConfirmationComponent
          subtitle={
            <p>
              Você deseja aprovar a pergunta{" "}
              <strong>{this.state.managerName}</strong>?
            </p>
          }
          id={ModalIdsEnum.APPROVED_QUESTION}
          onYesClick={this.approveQuestion}
        />
      </React.Fragment>
    );
  }
}

export default inject(
  "treasureStore",
  "menuStore",
  "questionStore"
)(withRouter(observer(ManagerTreasureQuestionsContainer)));
