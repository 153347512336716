import BaseModel from "./BaseModel";
import CustomerModel from "./CustomerModel";
import TreasureModel from "./TreasureModel";

class EvaluationsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.question = data.question;
    this.uuid = data.uuid;
    this.admin = data.admin;
    this.approved = data.approved;
    this.starsQty = data.starsQty;
    this.treasure = new TreasureModel(data.treasure);
    this.customer = new CustomerModel(data.customer);
  }
}

export default EvaluationsModel;
