import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import FilterModel from "~/models/FilterModel";
import ReportsModel from "~/models/ReportsModel";
import ReportsAPI from "~/services/ReportsAPI";
import SalesAPI from "~/services/SalesAPI";

const initValues = {
  loading: false,
  reports: [],
  report: undefined,
  filter: undefined,
  type: undefined,
  page: 0,
  city: [],
  neighborhood :[],
  totalPages: 0,
  size: 10,
  pageFilter: 0,
};

class ReportsStore {
  totalPages = 0;
  size = 10;
  sort = "count,desc";
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  reset() {
    this.reports = [];
    this.report = undefined;
    this.filter = undefined;
    this.totalPages = 0;
    this.filter = undefined;
    this.city = [];
    this.neighborhood = []
    this.page = 0;
    this.size = 10;
    this.sort = "count,desc";
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage) {
    const size = 10;
    this.page = numPage;
    this.size = size;
    await this.getListAllUsers();
    return true;
  }

  async updateProp(prop, value) {
    const filter = this.filter ? this.filter : new FilterModel();
    filter[prop] = value;
    this.filter = new FilterModel(filter);
  }

  /**Busca todos os usuários e Tesouros */
  async getListAllUsers() {
    this.loading = true;
    let params = {};

    params = {
      state: this.filter?.state ? this.filter?.state.value : [],
      city: this.filter?.city ? this.filter?.city.value : [],
      block: this.filter?.neighborhood ? this.filter?.neighborhood.value : [],
      searchBy: this.filter?.type ? this.filter?.type.value : "all",
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    this.type = this.filter?.type?.value;

    const response = await ReportsAPI.getListAllUsers(params);
    this.loading = false;
    if (response.error) return (this.reports = []);
    this.reports = response.content.map((value) => new ReportsModel(value));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  /**Busca todos os usuários e Tesouros */
  async getListServices() {
    this.loading = true;

    const params = {
      state: this.filter?.state ? this.filter?.state.value : [],
      city: this.filter?.city ? this.filter?.city.value : [],
      block: this.filter?.neighborhood ? this.filter?.neighborhood.value : [],
      category: this.filter?.category ? this.filter?.category?.label : [],
      page: this.page,
      size: this.size,
      sort: "count,desc",
    };

    const response = await ReportsAPI.getListServices(
      params,
      this.filter?.state
    );
    this.loading = false;
    if (response.error) return (this.reports = []);
    this.reports = response.content.map((value) => new ReportsModel(value));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPageServices(numPage) {
    const size = 10;
    this.page = numPage;
    this.size = size;
    await this.getListServices();
    return true;
  }

  /**Busca as cidades mais pesquisadas sem Tesouro */
  async getMostSearchedRegions() {
    this.loading = true;

    const params = {
      state: this.filter?.state ? this.filter?.state.value : [],
      city: this.filter?.city ? this.filter?.city.value : [],
      block: this.filter?.neighborhood ? this.filter?.neighborhood.value : [],
      page: this.page,
      size: this.size,
      sort: "total,desc",
    };

    const response = await ReportsAPI.getMostSearchedRegions(params);
    this.loading = false;
    if (response.error) return (this.reports = []);
    this.reports = response.content.map((value) => new ReportsModel(value));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPageMostSearchedRegions(numPage) {
    const size = 10;
    this.page = numPage;
    this.size = size;
    await this.getMostSearchedRegions();
    return true;
  }

  /**Busca as regiões por ranking */
  async getRatingRanking() {
    this.loading = true;

    const params = {
      state: this.filter?.state ? this.filter?.state.value : [],
      city: this.filter?.city ? this.filter?.city.value : [],
      block: this.filter?.neighborhood ? this.filter?.neighborhood.value : [],
      page: this.page,
      size: this.size,
      sort: this.sort ?? "avg",
    };

    const response = await ReportsAPI.getRatingRanking(params);
    this.loading = false;
    if (response.error) return (this.reports = []);
    this.reports = response.content.map((value) => new ReportsModel(value));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPageRatingRankin(numPage) {
    const size = 10;
    this.page = numPage;
    this.size = size;
    await this.getRatingRanking();
    return true;
  }

  /**
   * *Busca todas as cidades por estado
   * @param  {} city
   */
  async getAdressCity(city) {
    const response = await ReportsAPI.getAdressCity(city);
    if (!response.error) {
      this.city = response;
      return this.city;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Nenhum tesouro encontrado no estado selecionado"
    );
    return response;
  }

  async getNeighborhood(state,city) {

    const response = await ReportsAPI.getNeighborhood(state.value,city);
    if (!response.error) {
      this.neighborhood = response;
      return this.neighborhood;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Nenhum tesouro encontrado no estado selecionado"
    );
    return response;
  }
}

export default ReportsStore;
