import ProductModel from '../ProductModel';
import FileModel from './FileModel';

class InsumosHolambraProductModel extends ProductModel {
  constructor(data = {}) {
    super(data);
    this['@class'] =
      'br.com.stoom.insumosholambra.model.InsumosHolambraProduct';
    this.files = data.files
      ? data.files.map((file) => new FileModel(file))
      : [];
    this.restrictedSelling = data.restrictedSelling || false;
    this.detailedDescription = data.detailedDescription;
    this.detailedDescriptionComplement = data.detailedDescriptionComplement;
    this.groupDescription = '';
    this.cubage = data.cubage;
    this.pathVideo = data.pathVideo;
    // this.unidade_alternativa = data.unidade_alternativa;
    this.productOriginCode = data.productOriginCode ;
  }
}

export default InsumosHolambraProductModel;
