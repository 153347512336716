import { extendObservable } from "mobx";
import UserModel from "./UserModel";
import AddressModel from "./AddressModel";
import { entityTypes } from "~/helpers/utils/Selects";
import {
  validateEmail,
  validateCNPJ,
  validateCPF,
} from "~/helpers/utils/Validators";
import BaseModel from "./BaseModel";
import CustomerType from "./CustomerTypeModel";
import FileModel from "./FileModel";

/**Modelo que guarda infos dos customers */

export const BIRTHDATE_ATTRIBUTE_CONFIG_UUID =
  "e7405047-c065-4159-8e84-739888301665";

class CustomerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.document = data.document;
    this.email = data.email;
    this.entity = "FISICA";
    this.idCustomer = data.idCustomer;
    this.marketing = data.marketing;
    this.name = data.name;
    this.password = data.password;
    this.terms = data.terms;
    this.tokenSocial = data.tokenSocial;
    this.uuid = data.uuid;

    this.attributes = data.attributes;
    this.documentNormalized = data.documentNormalized;
    this.files = data.photo
      ? data.photo.map((file) => new FileModel(file))
      : [];
    this.customerDTO = data.customerDTO
      ? new CustomerModel(data.customerDTO)
      : null;
  }
  get birthdate() {
    if (this.attributes.length > 0)
      return this.attributes.find(
        (i) => i.attributeConfig.uuid == BIRTHDATE_ATTRIBUTE_CONFIG_UUID
      ).attributeValue.value;
    return "";
  }
}

export default CustomerModel;
