import React from "react";

/**
 * @Name: FormGroupComponent
 * @Data: 2019
 * @Desc: Div com classe bootstrap que agrupa diversos componentes
 * @props:
 *
 */

const FormGroupComponent = (props) => {
  // Recebe as propriedades
  const {
    className = "form-group",
    row = true,
    children,
    invalidText = "Campo Inválido",
    hidden,
  } = props;

  return (
    <div className={`${className} ${row ? "row" : "column"}`}>
      {children}
      <div className="invalid-feedback">{invalidText}</div>
    </div>
  );
};

export default FormGroupComponent;
