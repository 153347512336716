import React from "react";
import ButtonComponent from "~/components/ButtonComponent";
import CardPainelComponent from "~/components/CardPainelComponent/CardPainelComponent";

import HeaderListComponent from "~/components/HeaderListComponent";
import PaginationComponent from "~/components/PaginationComponent/PaginationComponent";
import SearchComponent from "~/components/SearchComponent";
import SelectComponent from "~/components/SelectComponent/SelectComponent";
import ListPriceAPI from "~/services/ListPrice";

function ListPriceComponent(props) {
  const { list } = props;
  const [priceList, setPriceList] = React.useState(null);
  const [filters, setFilters] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    getList();
  }, [currentPage]);

  const items = () => [
    { size: "2", callback: (e) => {}, label: "Nº da Lista" },
    { size: "4", callback: (e) => {}, label: "Produto" },
    { size: "2", callback: (e) => {}, label: "Estado" },
    { size: "2", callback: (e) => {}, label: "CFOP" },
    { size: "2", callback: (e) => {}, label: "Preço" },
  ];

  const getList = React.useCallback(async () => {
    const response = await ListPriceAPI.getProducts(filters, currentPage);
    setPriceList(response);
    setTotalPages(response.totalPages);
  }, [filters, currentPage]);

  const formatMoney = (value) => {
    return Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };
  const dashboard = false;
  const estados = [
    {
      value: "Selecione um Estado",
      label: null,
    },
    {
      value: "AC",
      label: "Acre",
    },
    {
      value: "AL",
      label: "Alagoas",
    },
    {
      value: "AP",
      label: "Amapá",
    },
    {
      value: "AM",
      label: "Amazonas",
    },
    {
      value: "BA",
      label: "Bahia",
    },
    {
      value: "CE",
      label: "Ceará",
    },
    {
      value: "DF",
      label: "Distrito Federal",
    },
    {
      value: "ES",
      label: "Espirito Santo",
    },
    {
      value: "GO",
      label: "Goiás",
    },
    {
      value: "MA",
      label: "Maranhão",
    },
    {
      value: "MS",
      label: "Mato Grosso do Sul",
    },
    {
      value: "MT",
      label: "Mato Grosso",
    },
    {
      value: "MG",
      label: "Minas Gerais",
    },
    {
      value: "PA",
      label: "Pará",
    },
    {
      value: "PB",
      label: "Paraíba",
    },
    {
      value: "PE",
      label: "Pernambuco",
    },
    {
      value: "PI",
      label: "Piauí",
    },
    {
      value: "RJ",
      label: "Rio de Janeiro",
    },
    {
      value: "RN",
      label: "Rio Grande do Norte",
    },
    {
      value: "RS",
      label: "Rio Grande do Sul",
    },
    {
      value: "RN",
      label: "Rondônia",
    },
    {
      value: "RR",
      label: "Roraima",
    },
    {
      value: "SC",
      label: "Santa Catarina",
    },
    {
      value: "SP",
      label: "São Paulo",
    },
    {
      value: "SE",
      label: "Sergipe",
    },
    {
      value: "TO",
      label: "Tocantins",
    },
  ];
  return (
    <div
      style={{
        height: 900,
      }}
    >
      <CardPainelComponent
        loading={false}
        large={true}
        title={"Lista de Preços"}
        subtitle={"Gerenciamento de lista de preços"}
        iconNew={null}
        className="mainPainel"
      >
        <div className="filter-row">
          {!dashboard && (
            <div className="filter-list">
              <SearchComponent
                placeholder="Filtrar por numero da lista"
                changeSearch={(value) => {
                  setFilters({
                    ...filters,
                    priceListNumber: value || null,
                  });
                }}
                value={null}
              />
              <SearchComponent
                placeholder="Filtrar por produto"
                changeSearch={(value) => {
                  setFilters({
                    ...filters,
                    productName: value.toUpperCase() || null,
                  });
                }}
                value={null}
              />
              <SearchComponent
                placeholder="Filtrar por sku"
                changeSearch={(value) => {
                  setFilters({
                    ...filters,
                    skuCode: value || null,
                  });
                }}
                value={null}
              />
              <div className="col-3">
                <SelectComponent
                  className="select-component"
                  autoFocus={false}
                  placeholder="Filtrar por estado"
                  defaultValue={estados.map((el) => {
                    if (el.value === filters?.state) {
                      return {
                        label: el.label,
                        value: el.value,
                      };
                    }
                  })}
                  items={estados}
                  onChange={(item) => {
                    setFilters({
                      ...filters,
                      state: item.value || null,
                    });
                  }}
                />
              </div>
              <div className="col-2">
                <button className="btn btn-primary " onClick={getList}>
                  <i className="fas fa-search mr-2" />
                  Buscar
                </button>
              </div>

              <div className="col-2">
                <button
                  className="btn btn-primary "
                  onClick={() => setFilters(null)}
                >
                  <i className="fas fa-trash mr-2" />
                  Limpar
                </button>
              </div>
            </div>
          )}
        </div>

        <section
          style={{
            marginTop: 20,
            height: 400,
          }}
        >
          <HeaderListComponent items={items()} />
          {priceList &&
            priceList.content.map((el) => (
              <div className="ListPriceComponent" key={el.id}>
                <>
                  <div className="col-2">{el.priceListNumber}</div>
                  <div className="col-4">{el.product.name}</div>
                  <div className="col-2">{el.state}</div>
                  <div className="col-2">{el.cfop}</div>
                  <div className="col-2">{formatMoney(el.price)}</div>
                </>
              </div>
            ))}
        </section>
        <PaginationComponent
          forcePage={currentPage}
          pages={totalPages}
          onChange={(selectedItem) => {
            setCurrentPage(selectedItem.selected);
            // this.filtersList.page = selectedItem.selected;
            // this.productQuestionStore.getList(this.filtersList);
          }}
        />
      </CardPainelComponent>
    </div>
  );
}

export default ListPriceComponent;
