import React, { useState, useEffect } from "react";

const TextareaComponent = ({
  onChange,
  className,
  name,
  id,
  rows,
  defaultValue,
  placeholder = "",
  disabled = false,
  maxlength = "5000",
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    e.stopPropagation();
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <textarea
      onChange={(e) => handleChange(e)}
      className={className}
      name={name}
      id={id}
      rows={rows}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      maxLength={maxlength}
    />
  );
};

export default TextareaComponent;
