import BaseModel from "./BaseModel";

export const BIRTHDATE_ATTRIBUTE_CONFIG_UUID =
  "e7405047-c065-4159-8e84-739888301665";

export default class ClientModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.document = data.document;
    this.email = data.email;
    this.entity = "FISICA";
    this.idCustomer = data.idCustomer;
    this.marketing = data.marketing;
    this.name = data.name;
    this.password = data.password;
    this.terms = data.terms;
    this.tokenSocial = data.tokenSocial;
    this.uuid = data.uuid;

    this.attributes = data.attributes ?? [];
  }
  get birthdate() {
    if (this.attributes.length > 0)
      return this.attributes.find(
        (i) => i.attributeConfig.uuid == BIRTHDATE_ATTRIBUTE_CONFIG_UUID
      ).attributeValue.value;
    return "";
  }
}
