import BaseAPI, { URLS } from "./BaseAPI";

class CustomerGroupAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.INSUMOS_HOLAMBRA}/types`,
        params
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar lista de grupo de usuários" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de grupo de usuários");
    }
  }

  /**
   * Request que cria novo grupo de usuário.
   * @param  {Object} groupCustomer - Objeto do grupo que sera criado
   */
  static async save(groupCustomer) {
    try {
      const response = await BaseAPI.post(
        `${URLS.INSUMOS_HOLAMBRA}/types`,
        groupCustomer
      );
      if (response.status === 200 || response.status === 201) return response;
      return { error: "Erro inesperado ao cadastrar o grupo de usuário" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar o grupo de usuário");
    }
  }

  /**
   * Request que atualiza um grupo de usuário.
   * @param  {Object} customerGroup - Objeto do grupo de usuário que será atualizado
   * @param  {String} uuid - Uuid do grupo de usuário que será atualizada
   */
  static async update(customerGroup, uuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.INSUMOS_HOLAMBRA}/types/${uuid}`,
        customerGroup
      );
      if (response.status === 200 || response.status === 201) return response;
      return { error: "Erro inesperado ao atualizar o grupo de usuário" };
    } catch (e) {
      return this._exception(e, "Falha ao atualizar o grupo de usuário");
    }
  }

  /**
   * Request que deleta um grupo de usuário.
   * @param  {String} uuid - Uuid do grupo de usuário que será deletado
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CUSTOMER_GROUP}/${uuid}`);
      if (response.status === 200 || response.status === 201) return response;
      return { error: "Erro inesperado ao deletar o grupo de usuário" };
    } catch (e) {
      return this._exception(e, "Falha ao deletar o grupo de usuário");
    }
  }

  /**
   * Request que busca grupo de usuário pelo uuid.
   * @param  {String} uuid - Uuid da grupo de usuário
   */
  static async getCustomerGroupByUuid(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.INSUMOS_HOLAMBRA}/types/${uuid}/?by=uuid`
      );
      if (response.status === 201 || response.status === 200)
        return response.data;
      return { error: "Erro ao recuperar informação do grupo de usuário!" };
    } catch (e) {
      return this._exception(
        e,
        "Erro ao recuperar informação do grupo de usuário!"
      );
    }
  }

  /**
   * Request que busca os nome de usuário para ser usado no criterio.
   * @param  {object} params - parametros de paginação
   */
  static async getCustomerByName(params) {
    try {
      const response = await BaseAPI.get(`${URLS.INSUMOS_HOLAMBRA}`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar lista de usuários" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de usuários");
    }
  }

  /**
   * Request que ativa/inativa um grupo de usuário.
   * @param  {String} uuid - Uuid do grupo de usuário que será atualizada
   */
  static async statusChange(uuid) {
    try {
      const response = await BaseAPI.patch(
        `${URLS.CUSTOMER_GROUP}/${uuid}/setactive`
      );
      if (response.status === 200 || response.status === 204) return response;
      return { error: "Erro inesperado ao atualizar o grupo de usuário" };
    } catch (e) {
      return this._exception(e, "Falha ao atualizar o grupo de usuário");
    }
  }
}

export default CustomerGroupAPI;
