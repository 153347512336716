import { extendObservable } from "mobx";

import AdministratorAPI from "../services/AdministratorAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import AccountModel from "~/models/AccountModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";

class UsersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      _user: undefined, //Current user.
      loading: false,
    });
  }

  /** get Current user. */
  get user() {
    return this._user;
  }
  /**Set Current user */
  set user(value) {
    this._user = value;
  }

  /**Retorna merchanto do usuário */
  get userMerchant() {
    return this.user && this.user.merchant;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  /**
   * Busca todas as informações de um usuário administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await AdministratorAPI.getUserAdmin(userName);
    this.loading = false;
    if (!response.error) {
      this.user = new AccountModel(response);
      await this.rootStore.administratorStore.getAdminLogin(this.user);
      return this.user;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}
export default UsersStore;
