import { action, decorate, extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import ClientModel from "~/models/ClientModel";
import QuestionsModel from "~/models/QuestionsModel";
import MessageModel from "~/models/MessageModel";
import TreasureModel from "~/models/TreasureModel";
import MessageAPI from "~/services/MessageAPI";

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  messagens: [],
  question: undefined,
};

class MessageStore {
  totalPages = 0;
  page = 0;
  size = 20;
  sort = "name";
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.customerTypeStore.getList();
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 20;
    this.sort = "name";
    this.filter = "";
  }

  getTresureUuidQuestion = async (uuid) => {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
    };
    const response = await MessageAPI.getTresureUuidQuestion(uuid);
    if (!response.error) {
      this.messagens = await response.content.map(
        (prd) => new MessageModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  };

  /**Busca todos os Tesouros Filtrados */
  async getList(filterValue, valueInput) {
    this.loading = true;
    const filter = {
      value: valueInput ? valueInput : "",
    };

    const params = {
      by: filterValue ? filterValue : "",
      page: valueInput
        ? this.pageFilter
          ? this.pageFilter
          : (this.page = 0)
        : this.page,
      size: this.size,
    };
    const response = await MessageAPI.getList(params, filter);
    if (!response.error) {
      this.messagens = await response.content.map(
        (prd) => new MessageModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta produto */
  async approveQuestion(uuid, uuidTreasure) {
    this.loading = true;
    const response = await MessageAPI.approveQuestion(uuid);
    if (!response.error) {
      this.getList(uuidTreasure);
      this.toastHelper.notify(STATUS_HELPER.INFO, "Tesouro deletado");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  async updatePropTreasure(prop, value, data) {
    this.question = new MessageModel(data);
    this.question[prop] = value;
  }

  // Aprovaçaõ de Tesouros
  async editQuestionsTreasure() {
    this.loading = true;
    const response = await MessageAPI.editQuestionsTreasure(this.question);
    if (!response.error) {
      this.question = new MessageModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async setPage(numPage, size = 20, filterValue, valueInput) {
    this.page = numPage;
    this.size = size;
    await this.getList();
    return true;
  }
}

export default MessageStore;

decorate(MessageStore, {
  getList: action,
  createEmptyProduct: action,
  searchByName: action,
});
