import { extendObservable, decorate, action } from "mobx";
import AuthAPI from "../services/AuthAPI";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import RecoveryPasswordModel from "~/models/RecoveryPasswordModel";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

/**
 * Store que trata toda lógica de autenticação do usuário.
 */
class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      isAuthenticated: false,
      loading: false,
      authoritie: false,
      stepForgoutPassword: 0,
      forgout: undefined,
    });
    const rememberLogin = StorageUtil.getItem(KEYS.REMEMBER);
    if (rememberLogin === "true") this.autoLogin();
  }

  /**Verifica se os dados salvos na storage são válidos e faz o login automaticamente.*/
  async autoLogin() {
    this.loading = true;
    const bearerToken = StorageUtil.getItem(KEYS.REMEMBER);
    if (bearerToken) {
      this.isAuthenticated = true;
    } else {
      StorageUtil.cleanAll();
    }
    this.loading = false;
  }

  async updateProp(prop, value) {
    const forgout = this.forgout ? this.forgout : new RecoveryPasswordModel();
    forgout[prop] = value;
    this.forgout = new RecoveryPasswordModel(forgout);
  }

  get isStoomAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "STOOM";
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isSuperAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN";
  }

  /**Verifica se a credencial do usuário é de admin */
  get isAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "ADMIN";
  }

  /** Faz o login */
  async login(code, password) {
    this.loading = true;
    const data = {
      email: this.email,
      password,
    };
    const result = await AuthAPI.login(data, code);
    this.isAuthenticated = !result.error && true;
    if (!this.isAuthenticated) {
      StorageUtil.cleanAll();
    } else {
      StorageUtil.setItem(KEYS.REMEMBER, true);
      StorageUtil.setItem(KEYS.TOKEN_KEY, result.data);
    }
    this.loading = false;
    return this.isAuthenticated;
  }

  /** Faz o login */
  // async loginSecond(username, password) {
  //   this.loading = true;
  //   const result = await AuthAPI.login(username, password);
  //   this.loginAuthenticated = !result.error && true;
  //   if (!this.isAuthenticated) {
  //     StorageUtil.cleanAll();
  //     this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
  //   } else {
  //     StorageUtil.setItem(KEYS.TOKEN_KEY, result.data);
  //     this.email = username;
  //     this.token = result?.headers?.authorization;
  //     const dataCode = {
  //       username,
  //     };
  //     const resultMfa = await AuthAPI.mfaRequest(dataCode);
  //     if (!resultMfa) {
  //       this.toastHelper.notify(
  //         STATUS_HELPER.SUCCESS,
  //         "E-mail enviado com sucesso"
  //       );
  //     }
  //   }
  //   this.loading = false;
  //   return this.loginAuthenticated;
  // }

  /** MFA */
  async mfaRequest(username) {
    this.loading = true;
    const data = {
      email: username ? username : this.email,
    };
    const result = await AuthAPI.mfaRequest(data);
    this.loginAuthenticated = !result.error && true;
    if (!result.error) {
      this.email = username ? username : this.email;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "E-mail enviado com sucesso"
      );
    } else {
      StorageUtil.cleanAll();
      this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
    }
    this.loading = false;
    return this.loginAuthenticated;
  }

  /**Logout */
  async logout() {
    this.loading = true;
    await AuthAPI.logout();
    this.isAuthenticated = false;
    this.loading = false;
    return this.isAuthenticated;
  }

  /**Envia */
  async redefinePass(email) {
    const response = await AuthAPI.redefinePass(email);
    return response;
  }

  /** Faz o login */
  async ressetPassword() {
    const data = this.forgout;
    this.loading = true;
    const response = await AuthAPI.ressetPassword(data);
    this.loading = false;
    return response;
  }
}

export default AuthStore;

decorate(AuthStore, {
  autoLogin: action,
});
