import React from 'react';
import ProductAPI from '~/services/ProductAPI';
import ButtonComponent from '~components/ButtonComponent/ButtonComponent';
import ModalConfirmationComponent from '~components/ModalConfirmationComponent/ModalConfirmationComponent';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

const ButtonUpdateProductImages = () => {

  const [isloading, setIsloading] = React.useState(false)

  const handleImportProducts = async () => {
    const toast = new ToastHelper()
    setIsloading(true)
    const response = await ProductAPI.importImages();
    window.$('#modalImportImagesConfirm').modal('hide');
    if(response.error){
      setIsloading(false)
      toast.notify(
        STATUS_HELPER.ERROR,
        response.error,
        5000,
        false
      );
    }else{
      setIsloading(false)
      toast.notify(
        STATUS_HELPER.SUCCESS,
        'Imagens atualizadas com sucesso',
        5000,
        false
      );
    }
  
  };

  const handleConfirm = () => {
    window.$('#modalImportImagesConfirm').modal('show');
  };

  return (
    <>
      <ModalConfirmationComponent
        id="modalImportImagesConfirm"
        title="Importar imagens de produtos"
        loading={isloading}
        subtitle="Deseja importar as imagens do produto?"
        onYesClick={handleImportProducts}
      />

      <div style={{
        width: 'max-content',
        marginBottom: 10
      }}
      >
      </div>
    </>
  );
};

export default ButtonUpdateProductImages;
