/* eslint-disable class-methods-use-this */
import { extendObservable } from "mobx";

import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import ItemMenuModel from "~/models/ItemMenuModel";

class MenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      menus: [],
      dashboard: new ItemMenuModel(this.dashboardData),
      itemMenu: undefined,
      subItemMenu: undefined,
    });
  }

  /** Carrega menu */
  loadMenu() {
    const showMenuStoom = this.rootStore.authStore.isStoomAdmin;
    let items = this.itemsMenu;

    if (!showMenuStoom) {
      items = this.itemsMenu.filter((item) => item.title !== "Stoom");
    }

    this.menus = items.map((item) => new ItemMenuModel(item));
    if (StorageUtil.getItem(KEYS.PATH_MENU)) {
      this.setCurrentMenu(StorageUtil.getItem(KEYS.PATH_MENU));
    }
  }

  /** Altera o menu atual. */
  setCurrentMenu(path) {
    StorageUtil.setItem(KEYS.PATH_MENU, path);
    this.itemMenu = this.menus.find((item) => {
      const sub = item.subItems.find((subItem) => subItem.path === path);
      if (sub) {
        this.subItemMenu = sub;
        return item;
      }
      return null;
    });
  }

  /** Retorna o titulo do menu selecionado */
  get title() {
    if (!this.itemMenu) return this.dashboard.title;
    return this.itemMenu && this.itemMenu.title;
  }

  /** Retorna o subtitulo do submenu */
  get subtitle() {
    if (!this.subItemMenu) return this.dashboard.subtitle;
    return this.subItemMenu && this.subItemMenu.title;
  }

  /** Retorna se dashboard está visible */
  get dashboardVisible() {
    return !this.itemMenu;
  }

  /** Dados da dashboard */
  get dashboardData() {
    return {
      title: "Painel de Controle",
      subtitle: "Um painel completo para controlar seu site",
      icon: "/assets/svg/panel-icon.svg",
      subItems: [],
    };
  }

  /** Items de menu */
  get itemsMenu() {
    return Object.values({
      administrators: {
        title: "Administradores",
        subtitle: "Área de administradores",
        icon: "/assets/svg/admin-icon.svg",
        subItems: [
          {
            path: "/cadastrar-administrador",
            title: "Novo Administrador",
          },
          { path: "/administradores", title: "Administradores" },
        ],
      },

      usuários: {
        title: "Usuários",
        icon: "/assets/svg/customer-icon.svg",
        subItems: [
          { path: "/cadastrar-usuarios", title: "Novo Usuário" },
          { path: "/usuarios", title: "Usuários" },
        ],
      },
      tesouros: {
        title: "Tesouros",
        icon: "/assets/svg/treasure-icon.svg",
        subItems: [
          { path: "/cadastrar-tesouros", title: "Novo Tesouro" },
          { path: "/tesouros", title: "Tesouros" },
        ],
      },
      categorias: {
        title: "Categorias",
        icon: "/assets/svg/category-icon.svg",
        subItems: [
          { path: "/cadastrar-categoria", title: "Nova Categoria" },
          { path: "/categorias", title: "Categorias" },
        ],
      },
      comentarios: {
        title: "Perguntas",
        icon: "/assets/svg/question-icon.svg",
        subItems: [{ path: "/perguntas", title: "Perguntas" }],
      },
      avaliacoes: {
        title: "Avaliações",
        icon: "/assets/svg/rate-icon.svg",
        subItems: [{ path: "/avaliacoes", title: "Avaliações" }],
      },
      // mensagens: {
      //   title: "Mensagens",
      //   icon: "/assets/svg/mail.svg",
      //   subItems: [{ path: "/mensagens", title: "Mensagens" }],
      // },
      reports: {
        title: "Relatórios",
        subtitle: "Um painel completo para controlar seu site",
        icon: "/assets/svg/reports-icon.svg",
        subItems: [
          { path: "/total-usuarios", title: "Total por Tesouros" },
          { path: "/total-servicos", title: "Usuários por serviço" },
          {
            path: "/regioes-mais-pesquisadas",
            title: "Regiões mais pesquisadas",
          },
          { path: "/ranking-avaliacoes", title: "Ranking de Avaliações" },
        ],
      },
    });
  }
}

export default MenuStore;
