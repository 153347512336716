import React, { useState, useEffect } from "react";

/**
 * @Name: SearchComponent
 * @Data: 2019
 * @Desc: Componente de Busca
 * @props:
 *
 */

const SearchComponent = (props) => {
  // Recebe as propriedades
  const {
    placeholder = "O que você procura?",
    changeSearch,
    value = "",
  } = props;
  const [inputValue, setValue] = useState("");

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleEnter = (event) => {
    event.key === "Enter" && changeSearch(event.target.value);
  };

  return (
    <div className="search-area">
      <input
        className="form-control form-search-special"
        onKeyPress={(e) => handleEnter(e)}
        onChange={(e) => setValue(e.target.value)}
        value={inputValue}
        type="text"
        placeholder={placeholder}
      ></input>
    </div>
  );
};

export default SearchComponent;
