import { action, decorate, extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import QuestionsModel from "~/models/QuestionsModel";
import FilterModel from "~/models/FilterModel";
import QuestionsAPI from "~/services/QuestionsAPI";

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  questions: [],
  question: undefined,
  filter: undefined,
};

class QuestionStore {
  totalPages = 0;
  page = 0;
  size = 20;
  sort = "created,Desc";
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.customerTypeStore.getList();
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 20;
    this.sort = "name";
    this.filter = "";
  }

  getTresureUuidQuestion = async (uuid) => {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
    };
    const response = await QuestionsAPI.getTresureUuidQuestion(uuid);
    if (!response.error) {
      this.questions = await response.content.map(
        (prd) => new QuestionsModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else console.log(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  };

  async updateProp(prop, value) {
    const filter = this.filter ? this.filter : new FilterModel();
    filter[prop] = value;
    this.filter = new FilterModel(filter);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList();
  }

  /**Busca todos os Tesouros Filtrados */
  async getList() {
    this.loading = true;

    const params = {
      state: this.filter?.state ? this.filter?.state.value : [],
      city: this.filter?.city ? this.filter?.city : [],
      document: this.filter?.cpf ? this.filter?.cpf : [],
      name: this.filter?.name ? this.filter?.name : [],
      approved: this.filter?.approved ? this.filter?.approved : [],
      page: this.page,
      sort: this.sort,
      size: this.size,
    };

    const response = await QuestionsAPI.getList(params);
    if (!response.error) {
      this.questions = await response.content.map(
        (prd) => new QuestionsModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else console.log(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta produto */
  async approveQuestion(uuid, uuidTreasure) {
    this.loading = true;
    const response = await QuestionsAPI.approveQuestion(uuid);
    if (!response.error) {
      this.getList(uuidTreasure);
      this.toastHelper.notify(STATUS_HELPER.INFO, "Pergunta aprovada");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  async updatePropTreasure(prop, value, data) {
    this.question = new QuestionsModel(data);
    this.question[prop] = value;
  }

  // Aprovaçaõ de Tesouros
  async editQuestionsTreasure() {
    this.loading = true;
    const response = await QuestionsAPI.editQuestionsTreasure(this.question);
    if (!response.error) {
      this.treasure = new QuestionsModel(response);
      this.getList();
      this.toastHelper.notify(STATUS_HELPER.INFO, "Pergunta alterada");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async setPage(numPage) {
    const size = 20;
    this.page = numPage;
    this.pageFilter = numPage;
    this.size = size;
    await this.getList();
    return true;
  }
}

export default QuestionStore;

decorate(QuestionStore, {
  getList: action,
  createEmptyProduct: action,
  searchByName: action,
});
