export const GenderTypeEnum = {
  MASCULINO: "MASCULINO",
  FEMININO: "FEMININO",
  OUTROS: "OUTROS",
};

export const PropertyClassViewTypeEnum = Object.freeze({
  LIST: "LIST",
  PICTOGRAM: "PICTOGRAM",
});

export const ModalIdsEnum = Object.freeze({
  //Utilizando
  DELETE_MANAGER: "delete-manager",
  DELETE_CATEGORY: "delete-category",
  APPROVED_QUESTION: "enable-question",
  DELETE_IMAGE_TREASURE: "delete-image",
  EDIT_QUESTION: "edit-question",
  EDIT_QUESTION_MESSAGE: "edit-question-message",
  APPROVED_TREASURE: "approved-treasure",
  HIDDEN_TREASURE: "hidden-treasure",

  VIEW_INFO: "view-info",
  CHANGE_PASSWORD: "change-password",

  DISABLE_VARIATION: "disable-variation",
  DELETE_CUSTOMER: "delete-customer",
  FORGOUT_PASSWORD: "forgot-password",
  DELETE_PROPERTIE: "delete-propertie",
  DELETE_ATTRIBUTE: "delete-attribute",
  DELETE_COMPARATIVE: "delete-comparative",
  DELETE_ATTRIBUTE_ALIAS: "delete-attribute-alias",
  UNLINK_VARIATION: "unlink-variation",
  EDIT_VARIATION_IMAGES: "edit-varation-images",
  PRODUCT_KIT: "product-kit",
  DELETE_ORDINATION_DISCOUNT: "delete-ordination-discount",
  DELETE_CATEGORY_PROPERTIE: "delete-category-propertie",
  DELETE_COMPARATIVE_ITEM: "delete-comparative",
  DELETE_PRODUCT_ALTERNATIVE: "delete-product-alternative",
  DELETE_PRODUCT_KIT: "delete-product-kit",
  DELETE_IMAGE_CREATE_BANNER: "delete-image-create-banner",
  DELETE_IMAGE_UPDATE_BANNER: "delete-image-update-banner",
  DELETE_CAMPAIGN: "delete-campaign",
  VIEW_IMAGE_CREATE_BANNER: "view-image-create-banner",
  VIEW_IMAGE_UPDATE_BANNER: "view-image-update-banner",
  DELETE_PROMOTION: "delete-promotion-modal",
  DELETE_SHIPPING_COMPANY: "delete-shipping-company-modal",
  DELETE_ZIP_CODE_RANGE: "delete-zip-code-range-modal",
  DELETE_WEIGHT_RANGE: "delete-weight-range-modal",
  DELETE_ADMIN: "delete-administrator",
  DELETE_BANNERS: "delete-banners",
  DELETE_IMAGE_VARIATION_ASSOCIATED: "delete-imagem-variation-associated",
  REVERSAL_VALUE: "reversal-value-modal",
  IS_ALL_PRODUCTS_CAMPAIGN: "is-all-products-campaign",
  DELETE_COMPONENT_HOME_STORE: "delete-component-home-store",
  VIEW_COMPONENT_HOME_STORE: "view-component-home-store",
  DELETE_COMPONENT_MOBILE_HOME_STORE: "delete-component-mobile-home-store",
  VIEW_COMPONENT_MOBILE_HOME_STORE: "view-component-mobile-home-store",
  DELETE_PRODUCT_HOME_STORE: "delete-product-home-store",
  DELETE_NEWSLETTER: "delete-newsletter-modal",
});

export const CategoriesEnum = Object.freeze({
  COLECAO: "COLECAO",
  VITRINE: "VITRINE",
});

export const MerchantEnum = Object.freeze({
  STOOM_MERCHANT: "Merchant Stoom",
  LUMINI_MERCHANT: "Merchant Lumini",
  SPORTBAY_MERCHANT: "Merchant Default",
  INSUMOS_HOLAMBRA_MERCHANT: "Merchant Insumos Holambra",
});

export const ProductTypeEnum = {
  MODEL: "MODEL",
  VARIATION: "VARIATION",
  PART: "PART",
};

export const ComparativeTypeEnum = {
  TEXT: "TEXT",
  BOOLEAN: "BOOLEAN",
};

export const CriterionEnum = {
  CIDADE: "CIDADE",
  ESTADO: "ESTADO",
  NOME: "NOME",
};
