import React from "react";

/**
 * @Name: Item de Lista do Painel
 * @Data: 2019
 * @Desc: O Componente de lista (item)  dos cards
 * @props:
 *
 */

const ListPainelComponent = (props) => {
  // Recebe as propriedades
  const {
    name,
    date,
    onEdit,
    onDelete,
    onEditPassword,
    children,
    group,
    document,
    mobile,
    email,
  } = props;

  return (
    <>
      <div className="ListPainelComponent">
        {children || (
          <>
            <div
              className={name?.column ? name?.column : "col-3"}
              onClick={onEdit}
            >
              {name?.name}
            </div>
            {email && (
              <div
                className={email?.column ? email?.column : "col-3"}
                onClick={onEdit}
              >
                {email?.email}
              </div>
            )}
            {date && (
              <div className="col-3" onClick={onEdit}>
                {date}
              </div>
            )}
            {group && (
              <div className="col-2" onClick={onEdit}>
                {group}
              </div>
            )}
            {mobile && (
              <div className="col-2" onClick={onEdit}>
                {mobile}
              </div>
            )}
            {onEdit && (
              <div className="col-1">
                <i className="fas fa-edit" onClick={onEdit} />
              </div>
            )}
            {onDelete && (
              <div className="col-1">
                <i className="fas fa-trash" onClick={onDelete} />
              </div>
            )}
            {onEditPassword && (
              <div className="col-1">
                <i className="fa fa-key" onClick={onEditPassword} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ListPainelComponent;
