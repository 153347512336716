import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from '@sentry/react';
import App from "./pages/App/App";

Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_DSN ||
    "https://2a3f76151e64443297a689d265b1fd44@o4505330007605248.ingest.sentry.io/4505388212551680",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.REACT_APP_ENV || "development",
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
