import React, { useState } from 'react';
import HeaderListComponent from '~/components/HeaderListComponent';

const HeaderComponent = ({ onClick }) => {
  const [data, setData] = useState('created');
  const [active, setActive] = useState('active');
  const [name, setName] = useState('name');
  const [skuCode, setSkuCode] = useState('skuCode');

  const sortActive = () => {
    setActive(active === 'active' ? 'active,desc' : 'active');
    onClick(active);
  };

  const sortCreated = () => {
    setData(data === 'created' ? 'created,desc' : 'created');
    onClick(data);
  };

  const sortName = () => {
    setName(name === 'name' ? 'name,desc' : 'name');
    onClick(name);
  };

  const sortSKU = () => {
    setSkuCode(skuCode === 'skuCode' ? 'skuCode,desc' : 'skuCode');
    onClick(skuCode);
  };

  const items = () => [
    // { size: '1', callback: e=> {}, label: 'Número' },
    { size: '5', callback: () => sortName(), label: 'Nome' },
    { size: '3', callback: () => sortSKU(), label: 'SKU' },
    { size: '2', callback: () => sortCreated(), label: 'Criação' },
    { size: '1', callback: () => sortActive(), label: 'Ativo' },
    { size: '1', callback: () => {}, label: '' },
  ];

  return <HeaderListComponent items={items()} />;
};

export default HeaderComponent;
