import BaseModel from './BaseModel';
import ProductModel from './ProductModel';
import OrderModel from './OrderModel';

class RecoveryPasswordModel extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.email = data.email;
        this.token = data.token;
        this.password = data.password;
    }
}

export default RecoveryPasswordModel;
